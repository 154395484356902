import glassyTwoCircle from '../../Images/glassyTwoCircle.png'
import darkPurpleOneCircle from '../../Images/darkPurpleOneCircle.png'
import threeCircle from '../../Images/threeCircle.png'
import Spinner from '../../UtilityComponents/Spinner'
import twoCircle from '../../Images/twoCircle.png'
import whiteMicIcon from '../../Images/whiteMicIcon.png'
import whiteNotesIcon from '../../Images/whiteNotesIcon.png'
import whiteProfileIcon from '../../Images/whiteProfileIcon.png'

import React from 'react'
import useSessionInfoPageVM from './useSessionInfoPageVM'
import PATIENT_SEX_ENUM from '../../Enums/PATIENT_SEX_ENUM'
import CUSTOMER_TYPE_ENUM from '../../Enums/CUSTOMER_TYPE_ENUM'

import Navbar from '../../ReusableUI/Navbar'
import BgLoggedIn from '../../ReusableUI/BgLoggedIn'
import MainContentBasedOnCustomerType from './Subcomponents/MainContentBasedOnCustomerType'
import TpProgressIndicatorAndRemainingNotesMessage from './Subcomponents/TpProgressIndicatoAndRemainingNotesMessage'
import { Toaster, toast } from 'sonner'





export default function SessionInfoPage(){

  const viewModel = useSessionInfoPageVM();

  
  return(
    <div id="bg-logged-in" className="full-height-container bg-cover bg-center h-screen flex flex-col justify-start items-center">
      
      <BgLoggedIn/>

      <Navbar/>      

      <Toaster />

      <div id='stuff-below-the-nav-bar' className='flex flex-col items-center space-y-36 mt-4'>


        { viewModel.isLoadingPageData ? (
          
          <Spinner/>
          
        ) : (
          <>
            <TpProgressIndicatorAndRemainingNotesMessage viewModel={useSessionInfoPageVM} />

            {viewModel.customerType === CUSTOMER_TYPE_ENUM.LESS_THAN_10_NC_AND_NOT_SUBSCRIBED && (
              <p className='font-bold text-center -translate-y-6 text-white'>
                You have {10-viewModel.notesCreatedCount} free notes remaining
              </p>
            )}

            <MainContentBasedOnCustomerType viewModel={viewModel} />
          </>

        )}


      </div>

      
    </div>
  );
}
