import glassyTwoCircle from '../../../Images/glassyTwoCircle.png'
import darkPurpleOneCircle from '../../../Images/darkPurpleOneCircle.png'
import threeCircle from '../../../Images/threeCircle.png'
import CUSTOMER_TYPE_ENUM from "../../../Enums/CUSTOMER_TYPE_ENUM";
import Spinner from "../../../UtilityComponents/Spinner";

export default function TpProgressIndicatorAndRemainingNotesMessage({viewModel}){

  return(
    <>
    {viewModel.isLoadingPageData ? (
      <Spinner/>
    ):(
      <div id='tp-progress-indicator-and-divider' className='w-full absolute'>
        <div id='tp-progress-indicator'  className='flex flex-row justify-center items-center space-x-4' >
          
          <div id='info-col' className='flex flex-col items-center space-y-2 mr-4'>
            <img src={darkPurpleOneCircle} alt="oneCircle" className='size-6 lg:size-8'/>
            <p className='text-white font-semibold lg:text-base text-sm'>
              Info
            </p>
          </div>

          <div id='empty-rounded-rectangle' className='w-16 h-2 bg-white rounded-md -mt-8'/>

          <div id='recording-col' className='flex flex-col items-center space-y-2'>
            <img src={glassyTwoCircle} alt="twoCircle"  className='size-6 lg:size-8'/>
            <p className='text-white font-semibold lg:text-base text-sm opacity-50'>
              Recording
            </p>
          </div>

          <div id='empty-rounded-rectangle' className='w-16 h-2 bg-white rounded-md -mt-8'/>

          <div id='review-col' className='flex flex-col items-center space-y-2'>
            <img src={threeCircle} alt="threeCircle"  className='size-6 lg:size-8'/>
            <p className='text-white font-semibold lg:text-base text-sm opacity-50'>
              Review
            </p>
          </div>

        </div>

      <div id="navbar-divider" className="border-t border-gray-200 mt-2" />
      
      </div>
    )}
  </>
  )
}
