import PATIENT_SEX_ENUM from '../../Enums/PATIENT_SEX_ENUM';
import TRANSCRIPTION_PAGE_ENUM from '../../Enums/TRANSCRIPTION_PAGE_ENUM';
import React, { createContext, useState } from 'react'


export const TranscriptionProcessContext = createContext();

export const TranscriptionProcessContextProvider = ({ children }) => {

  // #region Transcription Page State Management Stuff
  const [currentTranscriptionPage, setCurrentTranscriptionPage] = useState(TRANSCRIPTION_PAGE_ENUM.SESSION_INFO);
  // #endregion Transcription Page State Management Stuff

  // #region patient info/metadata stuff
  const [patientName, setPatientName] = useState("");
  const [patientAge, setPatientAge] = useState(0);
  const [patientSex, setPatientSex] = useState(PATIENT_SEX_ENUM.none_selected)
  // #endregion

    // #region Captured Audio stuff
    const [audioChunks, setAudioChunks] = useState([]);
    const [mimeTypeSupportedByTheUsersBrowser, setMimeTypeSupportedByTheUsersBrowser] = useState("");
    const [objectKey, setObjectKey] = useState('');
    const [audioBlobRef, setAudioBlobRef] = useState(null);
    const [audioBlobUrl, setAudioBlobUrl] = useState('');
    const addAudioChunk = (chunks) => {
      setAudioChunks(prevChunks => [...prevChunks, chunks]);;
    };
  
    const clearAudioChunks = () => {
      setAudioChunks([]);
    };
  
    const getAudioBlob = (mimeType) =>{
      return new Blob(audioChunks, { type: mimeType});
    }
  
    // #endregion
  
    // #region Correction Page stuff
    const [rawTranscriptDictList, setRawTranscriptDictList] = useState([]);
    const [wordsToLookAtList, setWordsToLookAtList] = useState([]);
    // #endregion

    // #region Soap note stuff
    const [soapNoteId, setSoapNoteId] = useState('');
    const [completedSoapNoteDict, setCompletedSoapNoteDict] = useState({});
    const [patientData, setSoapNote] = useState(null);
  
    const storePatientData = (data) => {
      setSoapNote(data)
    };
  
    const clearPatientData = () => {
      setSoapNote(null);
    }
    // #endregion

  return(
    <TranscriptionProcessContext.Provider value={{
      audioBlobRef, setAudioBlobRef,
      audioBlobUrl, setAudioBlobUrl,
      audioChunks, addAudioChunk, clearAudioChunks, getAudioBlob,
      completedSoapNoteDict, setCompletedSoapNoteDict,
      currentTranscriptionPage, setCurrentTranscriptionPage, 
      mimeTypeSupportedByTheUsersBrowser, setMimeTypeSupportedByTheUsersBrowser,
      objectKey, setObjectKey,
      patientAge, setPatientAge,
      patientData, storePatientData, clearSoapNote: clearPatientData,
      patientName, setPatientName,
      patientSex, PATIENT_SEX_ENUM, setPatientSex,
      rawTranscriptDictList, setRawTranscriptDictList,
      soapNoteId, setSoapNoteId,
      wordsToLookAtList, setWordsToLookAtList,
      
    }}>{
      children
    }</TranscriptionProcessContext.Provider>
  );
};


