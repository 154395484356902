import axios from 'axios';
import CREATE_ACCOUNT_ENUM from '../../Enums/CREATE_ACCOUNT_ENUM';
import { useState } from 'react';
import { validateEmail } from '../../UtilityFunctions/utils';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { doc, getDoc, getDocs, setDoc, Timestamp, collection, query, where } from 'firebase/firestore';
import { db } from '../../Configs/firebaseConfig';
import { getIso8601Timestamp } from '../../UtilityFunctions/utils';


const useCreateAccountPageVM = () => {

  // #region Properties
  const [authErrorTextIsPresented, setAuthErrorTextIsPresented] = useState(false);
  const [currentStep, setCurrentStep] = useState(CREATE_ACCOUNT_ENUM.NAMES_AND_EMAIL);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate();


  const [phoneNumber, setPhoneNumber] = useState('');
  const [serviceSID, setServiceSID] = useState('');


  const [confirmedCode, setConfirmedCode] = useState('');

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmedPassword: '',
    phoneNumber: '',
    confirmedCode: ''
  });
  // #endregion

  // #region Methods
  const formatConfirmedCode = (value) => {
    if (!value) return '';
    // Remove all non-numeric characters
    const numericValue = value.replace(/\D/g, '');
  
    // Limit to 6 characters
    return numericValue.slice(0, 6);
  }
    
  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const formatPhoneNumberForTwilio = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const formattedPhoneNumber = `+1${phoneNumber}`;
    return encodeURIComponent(formattedPhoneNumber);
  };

  const handleConfirmedCodeInputFieldEvent = (e) =>{
    const formattedCofirmedCode = formatConfirmedCode(e.target.value);
    setConfirmedCode(formattedCofirmedCode);
  }
    
  const handleContinueButtonClickOnNamesAndEmailStep = async () => {

    let errors = {};

    // #region Names and email validation format validation
    if (!firstName) {
      errors.firstName = 'First name is required';
    }
    if (!lastName) {
      errors.lastName = 'Last name is required';
    }
    if (!email) {
      errors.email = 'Email is required';
    } else if (!validateEmail(email)) {
      errors.email = 'Email is not valid';
    }
    // #endregion

    // #region if there are format validation errors, set error then return early
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    // #endregion

    // #region Check for internet connection, set error then return early if no connection
    if (!navigator.onLine) {
      errors.email = "Please check your internet connection"
      setErrors(errors);
      return;
    }
    // #endregion

    // #region Email in use validation with firestore
    try{
      const emailDoc = await getDoc(doc(db, "Users", email));
      if (emailDoc.exists()) {
        errors.email = 'This email is already in use';
      }

    } catch{

    }
    // #endregion

    // #region If email in use, set error and return early
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    // #endregion

    setCurrentStep(CREATE_ACCOUNT_ENUM.PASSWORDS);

  }

  const handleContinueButtonClickOnPasswordsStep = () => {
    let errors = {};
    if (!password) {
      errors.password = 'Password is required';
    } else if (password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }

    if (!confirmedPassword) {
      errors.confirmedPassword = 'Please confirm your password';
    } else if (confirmedPassword !== password) {
      errors.confirmedPassword = 'Passwords do not match';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setCurrentStep(CREATE_ACCOUNT_ENUM.PHONE_NUMBER);
  }

  const handleContinueButtonClickOnPhoneNumberStep = async () => {
  
    let errors = {};
  
    // #region Phone number format validation
    if (!phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    } else if (phoneNumber.length !== 12) {
      errors.phoneNumber = 'Phone number is not valid';
    }
    // #endregion
  
    // #region if there are format validation errors, set them then return early
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    // #endregion
    
    // #region Check for internet connection, if no connection set error then return early
    if (!navigator.onLine) {
      errors.phoneNumber = 'Please check your internet connection';
      setErrors(errors)
      return;
    }
    // #endregion

    // #region Phone number in use check
    try {
      const q = query(collection(db, "Users"), where("phoneNumber", "==", phoneNumber));
      const querySnapshot = await getDocs(q);  
      if (querySnapshot.size > 0) {
        errors.phoneNumber = 'This phone number is already in use';
      }
    } catch (error) {
      //TODO: Handle this error
      errors.phoneNumber = 'An error occurred. Please try again later.';
    }
    // #endregion
  
    // #region If there are errors after phone number in use check, set them then return early
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    // #endregion
  
    // #region Check for internet connection, if no connection set error then return early
    if (!navigator.onLine) {
      errors.phoneNumber = 'Please check your internet connection';
      setErrors(errors)
      return;
    }
    // #endregion

    // I'm transitioning to the next step before sending the code so that the ux is better. 
    // i.e. the user is navigated instantly on click instead of waiting for the api request to be sent and response received.
    setCurrentStep(CREATE_ACCOUNT_ENUM.CONFIRM_CODE);
    
    // #region send verification code to phone number
    const formattedPhoneNumber = formatPhoneNumberForTwilio(phoneNumber);
    try {
      const response = await axios.post(
        'https://1vlrr1dc75.execute-api.us-east-2.amazonaws.com/send_verification_code_with_twilio?phoneNumber=' + formattedPhoneNumber,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          }
        }
      );
  
      // console.log('response', response);
      setServiceSID(response.data.service_sid);
  
      setErrors({});
    } catch (error) {
      setErrors({ phoneNumber: 'Failed to send verification code' });
    }
    // #endregion

  };
  
  const handleContinueButtonClickOnConfirmCodeStep = async () => {
    
    // #region Validation
    let errors = {};
    if (!confirmedCode) {
      errors.confirmedCode = 'Verification code is required';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    // #endregion

    // #region Check for internet connection, if no connection set error then return early
    if (!navigator.onLine) {
      errors.confirmedCode = 'Please check your internet connection';
      setErrors({errors})
      return;
    }
    // #endregion
    
    // #region Verify code
    const formattedPhoneNumber = formatPhoneNumberForTwilio(phoneNumber);
    try {
      const response = await axios.post(`https://98ymiopnab.execute-api.us-east-2.amazonaws.com/verify_code_with_twilio?phoneNumber=${formattedPhoneNumber}&code=${confirmedCode}&serviceSID=${serviceSID}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          }
        }
      )
      // console.log('response', response)
      setErrors({});
    } catch (error) {
      // console.log('error', error)
      setErrors({ confirmedCode: 'Incorrect code, try resending a new code' });
      return;
    }
    // #endregion

    try {
      // #region TODO: Create account
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password );
      const userId = userCredential.user.uid;
      // #endregion

      // #region Upload profile info to firestore
      const dateCreatedAsIso8601Timestamp = getIso8601Timestamp();
      const dateCreatedAsfirestoreTimestamp = Timestamp.fromDate(new Date());
      const userProfileInfo = {
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phoneNumber": phoneNumber,
        "userId": userId,
        "dateCreatedAsfirestoreTimestamp": dateCreatedAsfirestoreTimestamp,
        "dateCreatedAsIso8601Timestamp": dateCreatedAsIso8601Timestamp,
        "notesCreated": 0,
        "isSubscribed": false,
      }
      
      await setDoc(doc(db, "Users", email), userProfileInfo);

      navigate('/transcription-process');
      // #endregion
    } catch (error) {
      //TODO: Handle this error
    }

  }

  const handleLeftArrowClick = () => {
    switch (currentStep) {
      case CREATE_ACCOUNT_ENUM.PASSWORDS:
        setErrors({});
        setCurrentStep(CREATE_ACCOUNT_ENUM.NAMES_AND_EMAIL);
        break;
      case CREATE_ACCOUNT_ENUM.PHONE_NUMBER:
        setErrors({});
        setCurrentStep(CREATE_ACCOUNT_ENUM.PASSWORDS);
        break;
      case CREATE_ACCOUNT_ENUM.CONFIRM_CODE:
        setErrors({});
        setCurrentStep(CREATE_ACCOUNT_ENUM.PHONE_NUMBER);
        break;
      default:
        break;
    }
  }

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };

  const handleResendButtonClick = async () => {
    const formattedPhoneNumber = formatPhoneNumberForTwilio(phoneNumber);
    try {
      const response = await axios.post('https://1vlrr1dc75.execute-api.us-east-2.amazonaws.com/send_verification_code_with_twilio?phoneNumber='+formattedPhoneNumber,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          }
        }
      )

      // console.log('response', response)
      setServiceSID(response.data.service_sid);

      setErrors({});
    } catch (error) {
      setErrors({ phoneNumber: 'Failed to send verification code' });
    }
  }
  
  const togglePasswordVisibility = () => {
    setPasswordVisible(prevState => !prevState);
    // console.log('showPassword', passwordVisible)
  }
  // #endregion

  return {

    confirmedCode,

    currentStep,

    errors,

    handleConfirmedCodeInputFieldEvent,

    handleContinueButtonClickOnConfirmCodeStep,

    handleContinueButtonClickOnNamesAndEmailStep,

    handleContinueButtonClickOnPasswordsStep,

    handleContinueButtonClickOnPhoneNumberStep,

    handleLeftArrowClick,

    handlePhoneNumberChange,

    handleResendButtonClick,

    passwordVisible,

    phoneNumber,

    setConfirmedPassword, confirmedPassword,

    setEmail, email,

    setFirstName, firstName,
    
    setLastName, lastName,
    
    setPassword, password,

    togglePasswordVisibility,

  };


}

export default useCreateAccountPageVM;