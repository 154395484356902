import AWS from 'aws-sdk';
import awsConfig from '../../Configs/awsConfig'; 
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../UtilityComponents/GlobalContextProvider.js'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TranscriptionProcessContext } from '../Transcription_Process_MultiPage/TranscriptionProcessContext.jsx';
import TRANSCRIPTION_PAGE_ENUM from '../../Enums/TRANSCRIPTION_PAGE_ENUM.js';
import S3 from 'aws-sdk/clients/s3.js'; 




// #region AWS setup
AWS.config.update({
  region: awsConfig.s3.region,
  accessKeyId: awsConfig.s3.accessKeyId,
  secretAccessKey: awsConfig.s3.secretAccessKey,
});

const s3 = new AWS.S3({
  region: awsConfig.s3.region,
  accessKeyId: awsConfig.s3.accessKeyId,
  secretAccessKey: awsConfig.s3.secretAccessKey
});

var ddb = new AWS.DynamoDB({ apiVersion: "2012-08-10" });
// #endregion AWS setup





const UploadInProgressVM = ()=>{
  
  // #region Context(s)
  const { user } = useContext(GlobalContext)
  const {
    clearAudioChunks, getAudioBlob,
    mimeType,
    objectKey,
    patientName, patientAge, patientSex,
    setCurrentTranscriptionPage,
    storePatientData,
  } = useContext(TranscriptionProcessContext)
  // #endregion


  // #region Properties
  const [dataIsStored, setDataIsStored] = useState(false);
  const navigate = useNavigate();
  const [polling, setPolling] = useState(true);
  // #endregion


  // #region Methods
  function getIso8601Timestamp() {
    const date = new Date();
    return date.toISOString();  // Outputs in ISO 8601 format, e.g., "2024-04-23T12:34:56.789Z"
  }

  const pollForUpdates = ()=>{
    const interval = setInterval(async () => {
      try {
        // const testObjectKey = '038bfe55-b8fa-48a2-9a5b-a5268e0344e2.mpeg'
        const response = await axios.post(
          // `https://qii08l5lgl.execute-api.us-east-2.amazonaws.com/v1/fetch_note?object_key=${testObjectKey}`,
          `https://qii08l5lgl.execute-api.us-east-2.amazonaws.com/v1/fetch_note?object_key=${objectKey}`,
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "x-api-key": process.env.REACT_APP_AWS_API_KEY
            }
          }
        )
        // console.log('SOAP Note:', response.data);
        if (response.data && response.data.COMPLETION_STATUS === 'Processed') {
          // console.log("response.data.status is", response.data.COMPLETION_STATUS)
          clearInterval(interval);
          setPolling(false); // Stop polling
          storePatientData(response.data);
          setDataIsStored(true);
        }
      } catch (error) {
        // console.error('Error fetching SOAP note:', error);
        clearInterval(interval);
        setPolling(false); // Stop polling in case of error too
      }
    }, 5000); // Poll every 5000 milliseconds (5 seconds)

    return () => clearInterval(interval); // Cleanup function to stop polling
  };

  const setupRefreshWarning = ()=>{
    const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = ''; // Chrome requires returnValue to be set.
        return "Are you sure you want to refresh? You'll be redirected to a new session.";
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  };

  const uploadAudioToS3 = ()=>{
    // console.log("Attempting to start upload");
    const audioBlob = getAudioBlob(mimeType);
    s3.upload({
      Bucket: awsConfig.s3.buckets.mmBucketv2,
      Key: objectKey,
      Body: audioBlob,
      ContentType: mimeType
    }, (err, data) => {
      if (err) {
        // console.error("The following error occurred", err);
        return;
      }
      if (data) {
        writeToDdb();
        clearAudioChunks();
        pollForUpdates();
      }
    });
  };

  const uploadToAFRTBucket = ()=>{
    const audioBlob = getAudioBlob(mimeType);
    return s3.upload({
      Bucket: process.env.REACT_APP_AFRT_BUCKET_NAME,
      Key: objectKey,
      Body: audioBlob,
      ContentType: mimeType
    }).promise();
  };

  const handleAFTRUploadSuccess = ()=>{
    // writeToDdb();
    clearAudioChunks();
    // pollForUpdates();
  };

  const writeToDdb = ()=>{
    const timestamp = getIso8601Timestamp();
    const patientAgeString = patientAge.toString()
    var params = {
      TableName: "SOAP_NOTES",
      Item: {
        "OBJECT_KEY": {"S": objectKey},
        "PATIENT_NAME": {"S": patientName},
        "PATIENT_AGE": {"S": patientAgeString},
        "PATIENT_SEX": {"S": patientSex},
        "COMPLETION_STATUS": {"S": "In Progress"},
        "USER_ID": {"S": user.uid},
        "DDB_TIMESTAMP": {"S": timestamp},
      }
    }
    ddb.putItem(params, function(error, data){
      if (error){
        console.err("Failed to add item to db because of", error)
        return;
      }
      if (data){
        // console.log("Successfully uploaded item to dynamodb")
        return;
      }
    });
  };
  // #endregion


  // #region useEffect
  useEffect(()=>{
    const warnOnRefreshAttempt = setupRefreshWarning();
    // if (!dataIsStored){
      uploadToAFRTBucket()
        .then(handleAFTRUploadSuccess)
        .catch((error)=>{
          // console.error("Error uploading to AFRT bucket:", error);
        });
    // } else { 
    //    setCurrentTranscriptionPage(TRANSCRIPTION_PAGE_ENUM.SOAP_NOTE)
    // }

    return ()=>{
      warnOnRefreshAttempt();
    }

  }, [dataIsStored, navigate])
  // #endregion


  return { };

};

export default UploadInProgressVM;