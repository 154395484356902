import { useState, useEffect } from 'react';

export default function useTypingEffect(text = '', startTyping, speed = 100) {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    if (!startTyping || !text) return;

    let currentIndex = 0;
    const normalizedText = text.trim().replace(/\r\n|\r|\n/g, '\n'); // Normalize newlines

    const timer = setInterval(() => {
      const currentChar = normalizedText[currentIndex];
      if (currentChar !== undefined) {
        setDisplayedText(prev => prev + currentChar);
      }
      currentIndex += 1;
      if (currentIndex === normalizedText.length) {
        clearInterval(timer);
      }
    }, speed);

    return () => clearInterval(timer);
  }, [startTyping, text, speed]);

  return displayedText;
}
