const awsConfig = {
  s3: {
    region: 'us-east-2',
    accessKeyId: process.env.REACT_APP_AWS_AK_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SAK,
    buckets:{
      audioBucket: 'audio-bucket-v1-01',
      audioChunkBucket: 'audio-chunks-mm',
      mmBucketv2: 'mm-bucket-v2',
      testBucket: 'test-bucket-mmv1'
    }
  },
};

export default awsConfig;
