import { faCopy, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Spinner from '../../UtilityComponents/Spinner'
import useSoapNoteVM from './useSoapNoteVM';

import whiteMicIcon from '../../Images/whiteMicIcon.png'
import whiteNotesIcon from '../../Images/whiteNotesIcon.png'
import whiteProfileIcon from '../../Images/whiteProfileIcon.png'
import Navbar from '../../ReusableUI/Navbar';
import BgLoggedIn from '../../ReusableUI/BgLoggedIn';







export default function SoapNotePage(){
  
  const { 
    handleCopyClickFor,
    handleStartNewSessionButtonClick,
    loading, 
    patientName,
    sectionWhichWasCopiedMomentsAgo,
    soapNoteDict
  } = useSoapNoteVM();

  // #region Subcomponents
  function FormattedSection(text){
    if (text){
      return text.split('\n').map((item, index) => (
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
      ));
    }
  }

  function FormattedNumberedSection(text) {
    if (text){
      return text.split('\n').map((item, index) => (
        <React.Fragment key={index}>
          {item.trim().replace(/^\-/, '')} {/* Remove leading '-' and trim any extra space */}
          <br />
        </React.Fragment>
      ));
    }
  }  
  //#endregion
  

  return (
  <div id="bg-logged-in" className="full-height-container bg-cover bg-center h-screen flex flex-col justify-start items-center">
      
      <BgLoggedIn/>

      <Navbar/>

      <div id = 'stuff-on-top-of-note-glass-rectangle' className='flex flex-col items-center justify-start mt-4 space-y-8'>

        <div id='soap-note-metadata' className='flex flex-row items-start justify-between w-[450px] lg:w-[530px]'>

          <div className='flex flex-col space-y-6'>
            <p className='font-bold text-lg lg:text-2xl text-white'>
              SOAP Note
            </p>
            <p className='font-medium text-base text-white'>
              {patientName}
            </p>
          </div>

          <div id='start-new-session-button' className='flex items-start justify-end'>
            <button id="start-new-session-button" className="relative px-8 py-3 bg-gray-900 w-50 rounded-3xl hover:opacity-50 flex-none"
              onClick={handleStartNewSessionButtonClick}
            >
                <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
                <p className="text-[#A343FF] font-bold relative">Start new session</p>
            </button>
          </div>

        </div>

        <div id='copy-instruction'>
          <p className='font-bold text-white text-md text-opacity-60'>
            Click on a section to copy it to your clipboard
          </p>
        </div>

      </div>

      <div id="metadata-and-soap-note" className='w-full overflow-y-auto flex flex-col items-center space-y-36'>
        
        <div id="soap-note-form" 
          style={{ backgroundColor: 'rgba(221, 248, 252, 0.2)' }} 
          className="absolute btn-shadow flex flex-col justify-start items-start pt-8 rounded-xl space-y-8 w-[450px] lg:w-[530px] h-[450px]"
        >

          {loading ? (
            <div className='w-[530px] h-[450px] items-center justify-center mt-24'>
              <Spinner />
            </div>
          ) : (
            <div id="soap-note-sections" className='flex flex-col h-[450px] overflow-y-auto px-4 space-y-16 w-[450px] lg:w-[530px]'>


              {/* Repeatable Section */}
              {[
                { id: 'CHIEF_COMPLAINT', label: 'Chief Complaint' },
                { id: 'HISTORY_OF_PRESENT_ILLNESS', label: 'History of Present Illness' },
                { id: 'PAST_MEDICAL_HISTORY', label: 'Past Medical History' },
                { id: 'FAMILY_HISTORY', label: 'Family History' },
                { id: 'SOCIAL_HISTORY', label: 'Social History' },
                { id: 'REVIEW_OF_SYSTEMS', label: 'Review of Systems' },
                { id: 'ALLERGIES', label: 'Allergies' },
                { id: 'VITALS', label: 'Vitals' },
                { id: 'ASSESSMENT', label: 'Assessment' },
                { id: 'PLAN', label: 'Plan', isNumbered: true }
              ].map(section => (
                <div key={section.id} id={`${section.id.toLowerCase()}-div`} className='hover:opacity-50 space-y-2'
                  onClick={() => handleCopyClickFor(section.id)}>
                  <div className='flex flex-row items-center space-x-4 text-white'>
                    <FontAwesomeIcon 
                      icon={ sectionWhichWasCopiedMomentsAgo === section.id ? faCheckCircle : faCopy}
                      style={{ color: sectionWhichWasCopiedMomentsAgo === section.id ? 'green' : 'inherit' }}
                    />
                    <p className='text-white font-bold text-lg'>{section.label}</p>
                    <p className={`text-white font-bold text-sm ${sectionWhichWasCopiedMomentsAgo === section.id ? 'text-opacity-100' : 'text-opacity-0'}`}>
                      copied
                    </p>
                  </div>
                  <p className='font-normal leading-10 text-white text-sm text-start opacity-75'>
                    <strong>
                      {section.isNumbered ? FormattedNumberedSection(soapNoteDict[section.id], true) : FormattedSection(soapNoteDict[section.id])}
                    </strong>
                  </p>
                </div>
              ))}

            </div>
          )}
        </div>

      </div>

      

    </div>
  );

};