import PATIENT_SEX_ENUM from '../../../Enums/PATIENT_SEX_ENUM'

export default function SessionInfoForm ({viewModel}){ 
  

  return(
    <div id="session-info-form"
    style={{backgroundColor: 'rgba(221, 248, 252, 0.2)'}} 
    className="absolute btn-shadow flex flex-col justify-center items-center px-4 py-6 rounded-xl space-y-8 w-[400px]"
  >
    <div id='patient-inputs-col' className='flex flex-col space-y-4'>
      <div id="session-info-title-div" className='text-white font-semibold lg:text-3xl text-2xl'>
        Session Info.
      </div>

      <div id='patient-name-div' className='flex flex-col space-y-1'>
        <p id="patient-label" className='font-medium text-white'>
          Patient name
        </p>

        <textarea id='patient-name-textarea'
          className="border-2 border-gray-300 w-[300px] h-10 rounded px-2 py-2 resize-none text-black"
          data-testid="patient-age-textarea" 
          onChange={e => viewModel.setPatientName(e.target.value)} 
          value={viewModel.patientName}
        />

        {viewModel.errors.patientName && (
          <p id="patient-text-area-error" className='text-red-700 font-semibold'>
            {viewModel.errors.patientName}
          </p>
        )}
      </div>

      <div id='patient-age-div' className='flex flex-col space-y-1'>
        <p id="patient-label" className='font-medium text-white'>
          Patient age
        </p>

        <input id='patient-age-input-field'
          className="border-2 border-gray-300 w-[300px] h-10 rounded px-2 py-2 resize-none text-black"
          data-testid="patient-input-field"
          onChange={e => viewModel.setPatientAge(e.target.value)}
          type='number'
          value={viewModel.patientAge}
        />

        {viewModel.errors.patientAge && (
          <p id="patient-text-area-error" className='text-red-700 font-semibold'>
            {viewModel.errors.patientAge}
          </p>
        )}
      </div>

      <div id='patient-sex-div' className='flex flex-col space-y-1'>
        <p id="patient-label" className='font-medium text-white'>
          Patient sex
        </p>

        <select id='patient-sex-selector'
          className="border-2 border-gray-300 w-[300px] h-10 rounded px-2 py-2 resize-none text-black"
          onChange={e => viewModel.setPatientSex(e.target.value)}
          value={viewModel.patientSex} 
        >
          <option id='none-selected-option' value="">
            {PATIENT_SEX_ENUM.none_selected}
          </option>
          <option id='male-option' value="male">
            {PATIENT_SEX_ENUM.male}
          </option>
          <option id='female-option' value="female">
            {PATIENT_SEX_ENUM.female}
          </option>
        </select>

        {viewModel.errors.patientSex && (
          <p id="patient-text-area-error" className='text-red-700 font-semibold'>
            {viewModel.errors.patientSex}
          </p>
        )}
      </div>
    </div>

    <button id="start-session-button" className="relative py-3 bg-gray-900 w-[300px] rounded-3xl hover:opacity-50 flex-none"
      onClick={viewModel.handleStartSessionButtonClick}
    >
      <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
      <p className="text-[#A343FF] font-bold relative">Start Session</p>
    </button>
    </div>

  )
}
