import useManageSubscriptionPageVM from "./useManageSubscriptionPageVM";

import Navbar from "../../ReusableUI/Navbar";

import Spinner from "../../UtilityComponents/Spinner";

import BgLoggedIn from "../../ReusableUI/BgLoggedIn";

import { Toaster } from 'sonner'

export default function ManageSubscriptionPage() {

  const {

    cancelSubscriptionOperationsAreInProgress,
    
    cancelSubscriptionConfirmationDialogIsPresented,

    handleCancelSubscriptionButtonClick,

    handleNoGoBackButtonClick,

    handleConfirmCancellationButtonClick,

    handleOkayButtonClick,

    handleOkButtonClickOnSubscriptionCancellationFailedMessage,
    
    handleSubscribeButtonClick,

    isLoadingPageData,

    isSubscriber,

    refundAmount,

    subscriptionCancellationFailedMessageIsPresented,

    subscribeOperationsAreInProgress,

    subscriptionSuccessfullyCanceledDialogIsPresented,

    subscriptionEndDateAsString,

  } = useManageSubscriptionPageVM();

  return (
    <div id="bg-logged-in" className="full-height-container bg-cover bg-center h-screen flex flex-col justify-start items-center">
      <Navbar />

      <BgLoggedIn />

      <Toaster />

      { isLoadingPageData ? (
        <div id='spinner-div' className="mt-8">
          <Spinner />
        </div> 
      ) : (
        <>
          {/* Manage Subscription Form for subscribers */}
          { isSubscriber && (
          <div id="manage-subscription-form-1"
            className="btn-shadow flex flex-col justify-center items-center mt-32 px-4 py-8 rounded-xl space-y-8 w-[400px] bg-[#ddf8fc]/20"
          >
            <div className="text-white flex flex-col items-center justify-center space-y-4">
              <div className="text-lg font-bold">
                Magic Medic AI monthly subscription
              </div>
              <div className="text-lg font-medium opacity-50">
                $75/month
              </div>
              <div className="text-md font-medium opacity-50">
                Next payment: {subscriptionEndDateAsString}
              </div>

              <div className="flex justify-center">
                <button className="bg-red-500 text-white font-semibold py-2 px-4 rounded-3xl hover:opacity-50"
                  onClick={handleCancelSubscriptionButtonClick}
                >
                  Cancel subscription
                </button>
              </div>
            </div>
          </div>
          )}

          {/* Manage Subscription Form for non-subscribers */}
          { !isSubscriber && (
            <>
              { subscribeOperationsAreInProgress ? (
                <div id='spinner-div' className="mt-8">
                  <Spinner />
                </div>
              ):(
                <div id="manage-subscription-form-2"
                  className="btn-shadow flex flex-col justify-center items-center mt-32 px-4 py-8 rounded-xl space-y-8 text-white w-[400px] bg-[#ddf8fc]/20"
                >
  
                  <div className="text-lg font-bold">
                    Magic Medic AI monthly subscription
                  </div>
  
                  <div className="text-md font-medium opacity-50">
                    You don't have an active subscription
                  </div>
  
                  <button id="subscribe-button" 
                      className="relative py-3 bg-gray-900 w-[300px] rounded-3xl hover:opacity-50 flex-none"
                      onClick={handleSubscribeButtonClick}
                  >
                      <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
                      <p className="text-[#A343FF] font-bold relative">Subscribe for $75/mo</p>
                  </button>
  
                </div>
              )}
            </>
          )}
          
          { /* Confirmation Dialog for cancelling subscription */ }
          {cancelSubscriptionConfirmationDialogIsPresented && (
            <>
              <div id='blur-overlay' className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm" />

              <div className="fixed inset-0 flex items-center justify-center">
              <div className="bg-[#ddf8fc]/20 flex flex-col text-white rounded-xl font-semibold p-8 shadow-lg text-center">
                { cancelSubscriptionOperationsAreInProgress ? (
                      <Spinner />
                ): (
                    <>
                    <div id='cancel-sub-confirmation-dialog-content' className="flex flex-col space-y-6">
                      <h2 className="text-xl font-bold">Confirm Cancellation</h2>
                      <div className="flex flex-col space-y-2">
                        <p className="">Are you sure you want to cancel your subscription?</p>
                        <p className="">Your subscription will end immediately and you will be refunded:</p>
                        <p className="text-2xl font-bold">${refundAmount}</p>
                        <p className="">for the remaining days of your subscription</p>
                      </div>
                      <div className="flex justify-center space-x-4">
                        <button
                          className="bg-red-500 text-white font-semibold py-2 px-4 rounded-3xl hover:opacity-50"
                          onClick={handleConfirmCancellationButtonClick}
                        >
                          Yes, cancel
                        </button>
                        <button
                          className="bg-gray-500 text-white font-semibold py-2 px-4 rounded-3xl hover:opacity-50"
                          onClick={handleNoGoBackButtonClick}
                        >
                          No, go back
                        </button>
                      </div>
                    </div>
                    </>
                  )}

                  </div>
              </div>
            </>
          )}

          {(subscriptionCancellationFailedMessageIsPresented && (
            <>
            <div id='blur-overlay' className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm" />

            <div className="fixed inset-0 flex items-center justify-center">
              <div className="bg-[#ddf8fc]/20 flex flex-col text-white rounded-xl font-semibold p-8 shadow-lg text-center">
                <h2 className="mb-4">Cancellation Failed<br/>Please contact support@magicmedicai.com</h2>
                <button id="subscribe-button" 
                      className="relative py-3 bg-gray-900 w-[300px] rounded-3xl hover:opacity-50 flex-none"
                      onClick={handleOkButtonClickOnSubscriptionCancellationFailedMessage}
                  >
                      <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
                      <p className="text-[#A343FF] font-bold relative">Ok</p>
                  </button>

                
              </div>
            </div>
          </> 
            

          ))}
          
          { /* Confirmation Dialog for successfully canceled subscription */ }
          { subscriptionSuccessfullyCanceledDialogIsPresented && (
          <>
            <div id='blur-overlay' className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm" />

            <div className="fixed inset-0 flex items-start justify-center pt-20">
              <div className="bg-[#ddf8fc]/20 flex flex-col text-white rounded-xl font-semibold p-8 shadow-lg text-center">
                <h2 className="text-xl font-bold mb-4">Confirm Cancellation</h2>
                <p className="mb-2">Your subscription has been cancelled.</p>
                <p className="mb-8">You'll receive your refund in 1-3 business days</p>
                <div className="flex justify-center space-x-4">
                  <button id='okay-button'
                    className="bg-gray-500 text-white font-semibold py-2 px-4 rounded-3xl hover:opacity-50"
                    onClick={handleOkayButtonClick}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </>
          )}
        </>
      )}
    </div>


  );
}
