
import whiteMicIcon from '../../Images/whiteMicIcon.png'
import whiteNotesIcon from '../../Images/whiteNotesIcon.png'
import whiteProfileIcon from '../../Images/whiteProfileIcon.png'

import useNotesPageVM from './useNotesPageVM';
import Spinner from '../../UtilityComponents/Spinner'
import Navbar from '../../ReusableUI/Navbar';
import TestNotesList from './TestNotesList'; // for testing
import BgLoggedIn from '../../ReusableUI/BgLoggedIn';
import NOTES_LOADING_STATE_ENUM from '../../Enums/NOTES_LOADING_STATE_ENUM';
import { Toaster } from 'sonner'






export default function NotesPage() {

  const viewModel = useNotesPageVM();

  return(
    <div id="bg-logged-in" className='full-height-container bg-cover bg-center h-screen flex justify-start items-center'>

      <BgLoggedIn />

      <Navbar/>

      <Toaster />

      <div id='notes-title-and-notes-table-div' className='mt-8 space-y-4'>
        <div id='notes-title-div' className='text-white text-2xl font-bold text-center'>
          Notes
        </div>

        <div id="notes-table-div" 
          style={{backgroundColor: 'rgba(221, 248, 252, 0.2)'}} 
          className="btn-shadow flex flex-col justify-start items-start pt-8 rounded-xl space-y-8 text-sm w-[450px] h-[501px] "
        >
          <div id='notes-table-rows-div' className='flex flex-col text-white w-full'>
            <div id='title-row' className=' flex flex-col space-y-2'>
              <div id='title-row-div' className='flex flex-row font-bold opacity-60 justify-between w-full px-8'>
                <div id='name-p' className='text-start'>
                  Name
                </div>
                <div id='date-p' className='text-end'>
                  Date
                </div>
              </div>

              <div id="title-row-divider" className="border-t border-gray-200 my-0 opacity-50"></div>
            </div>

              { viewModel.loadingState === NOTES_LOADING_STATE_ENUM.LOADING && (
                <div className='w-[450px] h-[600px] items-center justify-center mt-24'>
                  <Spinner/>
                </div>
              )}
              { viewModel.loadingState === NOTES_LOADING_STATE_ENUM.LOADED_WITHOUT_NOTES && (
                <div className='flex justify-center items-center font-semibold text-base text-white mt-12 px-8'>
                  No SOAP note has been created yet. Click the recording button at the top to start.
                </div>            
              )}
              { viewModel.loadingState === NOTES_LOADING_STATE_ENUM.FINISHED_SUCCESSFULLY && (

                <div id='notes-scrollview' className='h-[435px] overflow-y-auto w-[530px] rounded-bl-xl rounded-br-xl'>
                  {viewModel.notes.map((note, index) => (
                    <>
                    {note.source === 'dynamoDb' && (
                      <button
                          key={note.soapNoteId} // Unique key for each child
                          className={`flex flex-col hover:opacity-50 ${index % 2 === 0 ? '' : 'bg-[rgba(221,248,252,0.2)]'}`}
                          onClick={()=>viewModel.handleSoapNoteRowClick(note.soapNoteId, note.source)}
                      >
                          <div id='note-row' className='flex flex-row font-medium justify-between w-[450px] px-8 py-4'>
                              <div id='name-div' className='text-start'>{note.patientName}</div>
                              <div id='date-div' className='text-end'>
                                  {new Date(note.timestamp).toLocaleString('en-US', {
                                      month: 'short',
                                      day: '2-digit',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: true
                                  })}
                              </div>
                          </div>
                          <div className="border-t border-gray-200 my-0 opacity-50"/>
                      </button>
                    )}  

                    </>
                  ))}
                </div>

              )}
              {viewModel.loadingState === NOTES_LOADING_STATE_ENUM.NO_INTERNET && (
                <div className='flex justify-center items-center font-semibold text-base text-center text-red-700 mt-12 px-8'>
                Please connect to the internet then refresh the page.
              </div>            
              )}

              {viewModel.loadingState === NOTES_LOADING_STATE_ENUM.ERROR_STATE && (
                <div className='flex justify-center items-center font-semibold text-base text-center text-red-700 mt-12 px-8'>
                An error occurred. Please check your internet connection then refresh the page.
                </div>
              )}

          </div>
        </div>
        
      </div>

    </div>
  );
};

                    