import AWS from 'aws-sdk';
import awsConfig from '../../Configs/awsConfig.js'; 
// import { auth } from '../../Configs/firebaseConfig';
import { GlobalContext } from '../../UtilityComponents/GlobalContextProvider.js';
import { useContext, useState } from 'react';
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import AUTH_STATE_ENUM from './AUTH_STATE_ENUM.js';

AWS.config.update({
  region: awsConfig.s3.region,
  accessKeyId: awsConfig.s3.accessKeyId,
  secretAccessKey: awsConfig.s3.secretAccessKey,
});


const useSignInPageVM = ()=>{

  // #region Properties
  const auth = getAuth();
  const [authErrorTextIsPresented, setAuthErrorTextIsPresented] = useState(false);
  const [currentUIState, setCurrentUIState] = useState(AUTH_STATE_ENUM.SIGN_IN);
  const [email, setEmail] = useState('');
  const [emailErrorTextIsPresented, setEmailErrorTextIsPresented] = useState(false);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { 
    setUser,
  } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }; 
  // #endregion

  // #region Methods  
    const validateEmail = (email)=>{
      const re = /\S+@\S+\.\S+/;
      return email && re.test(email);
    }
    // #endregion
  
  // #region Button Handlers
  const handleForgotPasswordButtonClick = ()=>{
    // console.log("Forgot password button clicked");
  }

  const handleMagicMedicAIButtonClick = ()=>{
    navigate('/')
  };

  const handleResetPasswordSubmission = async ()=>{
    setEmailErrorTextIsPresented(false);
    if (!validateEmail(email)) {
      setEmailErrorTextIsPresented(true);
      return;
    }
    
    try {
      await sendPasswordResetEmail(auth, email);
      setCurrentUIState(AUTH_STATE_ENUM.RESET_PASSWORD)
    } catch (error) {
      // console.error("Error sending password reset email", error);
      //TODO: Handle this error
    }
  }
    
  const handleSignInButtonClick = async ()=>{
    try {
      // Sign in to Firebase with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
  
      // Store the auth credentials in the global context and session storage
      setUser(userCredential.user);
  
      navigate('/transcription-process');
    } catch (error) {      
      //TODO: Handle this error
      setAuthErrorTextIsPresented(true);
    }    
  };
  // #endregion
  
  // #region Return
  return {
    authErrorTextIsPresented, setAuthErrorTextIsPresented,
    AUTH_STATE_ENUM,
    currentUIState, setCurrentUIState,
    email, setEmail, validateEmail,
    emailErrorTextIsPresented, setEmailErrorTextIsPresented,
    handleForgotPasswordButtonClick,
    handleMagicMedicAIButtonClick,
    handleResetPasswordSubmission, 
    handleSignInButtonClick,
    password, setPassword,
    showPassword, setShowPassword,
    togglePasswordVisibility
  };
  // #endregion

};

export default useSignInPageVM;