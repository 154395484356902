import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CreateAccountPage from './Pages/Create_Account_Page/CreateAccountPage';
import { GlobalContextProvider } from './UtilityComponents/GlobalContextProvider';
import JoinWaitlistPage from './Pages/Join_Waitlist_Page/JoinWaitlistPage';
import LandingPage from './Pages/Landing_Page/LandingPage';
import NotesPage from './Pages/Notes_Page/NotesPage';
import PasswordResetPage from './Pages/Password_Reset_Page/PasswordResetPage';
import ProtectedRoute from './UtilityComponents/ProtectedRoute';
import SigninPage from './Pages/Sign_In_Page/SignInPage';
import SoapNotePage from './Pages/Soap_Note_Page/SoapNotePage';
import { TranscriptionProcessContextProvider } from './Pages/Transcription_Process_MultiPage/TranscriptionProcessContext';
import TranscriptionProcessMultiPage from './Pages/Transcription_Process_MultiPage/TranscriptionProcessMultiPage';
import './tailwind.css';
import React, { useEffect } from 'react';
import LandingPage2 from './Pages/Landing_Page_2/LandingPage2';
import CanceledPaymentPage from './Pages/Canceled_Payment_Page/CanceledPaymentPage';
import SuccessfulPaymentPage from './Pages/Successful_Payment_Page/SuccessfulPaymentPage';
import ManageSubscriptionPage from './Pages/Manage_Subscription_Page/ManageSubscriptionPage';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { inject } from '@vercel/analytics';




export default function App() {
  useEffect(() => {
    inject();
  }, []);

  return (
    <GlobalContextProvider>
      
      <SpeedInsights />

      <TranscriptionProcessContextProvider>
        <BrowserRouter>
          <Routes>
            {/* Logged out */}
            <Route path="/" element={<LandingPage2 />} />
            <Route path="/create-account" element={<CreateAccountPage />} />
            <Route path="/password-reset" element={<PasswordResetPage />} />
            <Route path="/sign-in" element={<SigninPage />} />

            {/* Logged in */}
            <Route element={<ProtectedRoute />}>
              <Route path="/transcription-process" element={<TranscriptionProcessMultiPage />} />
              <Route path="/notes" element={<NotesPage />} />
              <Route path="/soap-note" element={<SoapNotePage />} />
              <Route path="/cancel" element={<CanceledPaymentPage />} />
              <Route path="/success" element={<SuccessfulPaymentPage />} />
              <Route path="/manage-subscription" element={<ManageSubscriptionPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </TranscriptionProcessContextProvider>
    </GlobalContextProvider>
  );
}
