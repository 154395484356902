import { TranscriptionProcessContext } from './TranscriptionProcessContext';

import CorrectionsPage from '../Corrections_Page/CorrectionsPage';
import RecordingSessionPage2 from '../Recording_Session_Page_2/RecordingSessionPage2';
import StartSessionPage from '../Start_Session_Page/StartSessionPage';
import SessionInfoPage from '../Session_Info_Page/SessionInfoPage';
import TRANSCRIPTION_PAGE_ENUM from '../../Enums/TRANSCRIPTION_PAGE_ENUM';
import TpSoapNotePage from '../TpSoap_Note_Page/TpSoapNotePage'
import UploadInProgressPage from '../Upload_In_Progress_Page/UploadInProgressPage';

import { useContext, useState } from 'react';



export default function TranscriptionProcessMultiPage(){
  
  const {
    currentTranscriptionPage
  } = useContext(TranscriptionProcessContext);

  return (
    <div>
      {currentTranscriptionPage === TRANSCRIPTION_PAGE_ENUM.SESSION_INFO && (
        <SessionInfoPage  />
      )}
      {currentTranscriptionPage === TRANSCRIPTION_PAGE_ENUM.RECORDING_SESSION && (
        <RecordingSessionPage2 />
      )}
      {currentTranscriptionPage === TRANSCRIPTION_PAGE_ENUM.CORRECTIONS && (
        <CorrectionsPage />
      )}
    </div>
  );
};