export const TestNotesList = [
  {
    "PATIENT_NAME": {"S": "John Doe"},
    "OBJECT_KEY": {"S": "lkafjakflj"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:09:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "Mary Smith"},
    "OBJECT_KEY": {"S": "key2fa123jk"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:15:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "Alice Johnson"},
    "OBJECT_KEY": {"S": "key3xc9123sd"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:21:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "Bob White"},
    "OBJECT_KEY": {"S": "key4qz4512ff"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:27:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "Patricia Brown"},
    "OBJECT_KEY": {"S": "key5pl6701mk"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:33:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "Michael Davis"},
    "OBJECT_KEY": {"S": "key6hv8902qw"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:39:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "Linda Miller"},
    "OBJECT_KEY": {"S": "key7xz4303ed"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:45:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "James Wilson"},
    "OBJECT_KEY": {"S": "key8kl5604po"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:51:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "Barbara Moore"},
    "OBJECT_KEY": {"S": "key9rq2505lm"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:57:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "William Taylor"},
    "OBJECT_KEY": {"S": "key10sg8476uj"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T11:03:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "Linda Miller"},
    "OBJECT_KEY": {"S": "key7xz4303"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:45:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "James Wilson"},
    "OBJECT_KEY": {"S": "key8kl5604"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:51:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "Barbara Moore"},
    "OBJECT_KEY": {"S": "key9rq2505"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T10:57:38.732Z"}
  },
  {
    "PATIENT_NAME": {"S": "William Taylor"},
    "OBJECT_KEY": {"S": "key10sg8476"},
    "DDB_TIMESTAMP": {"S": "2024-04-23T11:03:38.732Z"}
  }
];
export default TestNotesList;