import AWS from 'aws-sdk';
import awsConfig from '../../Configs/awsConfig.js'; 
import { getAuth, signOut } from "firebase/auth";
import React, { useContext, useEffect, useState } from 'react'
import { TranscriptionProcessContext } from '../Transcription_Process_MultiPage/TranscriptionProcessContext.jsx';
import { useNavigate } from "react-router-dom";
import TRANSCRIPTION_PAGE_ENUM from "../../Enums/TRANSCRIPTION_PAGE_ENUM.js";


// #region AWS setup
// I'm initializing these AWS services outside of the hook
// to avoid re-creating it on every render.
AWS.config.update({
  region: awsConfig.s3.region,
  accessKeyId: awsConfig.s3.accessKeyId,
  secretAccessKey: awsConfig.s3.secretAccessKey,
});

var ddb = new AWS.DynamoDB({ apiVersion: "2012-08-10" });
// #endregion



const useTpSoapNotePageVM = ()=>{

  // #region Context(s) 
    const {
      // patientData,
      soapNoteId,
      setCurrentTranscriptionPage
    } = useContext(TranscriptionProcessContext);
    // #endregion

  // #region Properties
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [patientName, setPatientName] = useState("");
  const [soapNoteDict, setSoapNoteDict] = useState({});
  const [sectionWhichWasCopiedMomentsAgo, setSectionWhichWasCopiedMomentsAgo] = useState("");
  // #endregion
  
  // #region Methods
  function copyToClipboard(text) {
    if (text){
      navigator.clipboard.writeText(text).then(() => {
        // console.log("Copied to clipboard successfully!");
      }, (err) => {
        // console.error("Failed to copy text: ", err);
      });
    }
  }

  const fetchDataItem = async (soapNotId) => {
    // #region #1. Define the params
    const params = {
      TableName: "SOAP_NOTES_TABLE",
      Key: { "SOAP_NOTE_ID": { S: soapNoteId } },
      ProjectionExpression: "SOAP_NOTE_DICT, PATIENT_NAME"
    }
    let Item
    // #endregion

    // #region #2. Fetch the completed SOAP note
    try{
      const retrievedData = await ddb.getItem(params).promise();
      Item = retrievedData.Item;
      return Item
    } catch (error){
      // console.error("Failed to fetch the completed SOAP note because of", error);
    }
    // #endregion
    
  }
    
  const fetchSoapNoteDictAndPatientName = async (soapNotId) => {
    // #region #1. Define the params
    const params = {
      TableName: "SOAP_NOTES_TABLE",
      Key: { "SOAP_NOTE_ID": { S: soapNoteId } },
      ProjectionExpression: "SOAP_NOTE_DICT, PATIENT_NAME"
    }
    let Item
    // #endregion

    // #region #2. Fetch the completed SOAP note
    try{
      const retrievedData = await ddb.getItem(params).promise();
      Item = retrievedData.Item;
      // console.log(Item)
    } catch (error){
      // console.error("Failed to fetch the completed SOAP note because of", error);
    }
    // #endregion
    
    // #region #3. Parse the data
    const patientName = Item.PATIENT_NAME.S;
    setPatientName(patientName)
    const completedSoapNoteDict = parseSoapNoteDict(Item);
    // #endregion

    // #region #4. Store the data in the context
    return completedSoapNoteDict
    // #endregion
  }
    
  function formatNumberedText(text) {
    if (text){
      return text.split('\n')
                  .map((item, index) => `${index + 1}. ${item.trim().replace(/^\-/, '')}`)
                  .join('\n');
    }
  }
    
  const handleCopyClickFor = (SECTION, isNumbered = false) => {
    if (!soapNoteDict){return;}
    let text = soapNoteDict[`${SECTION}`].replace(/\\n/g, '\n');
    // console.log("text is", text)
    if (isNumbered) {
      text = formatNumberedText(text);
    }
    copyToClipboard(text);
    showCopyIndicatorFor(SECTION)
  };

  const parseSoapNoteDict = (Item) => {
    const CHIEF_COMPLAINT = Item.SOAP_NOTE_DICT.M.CHIEF_COMPLAINT.S
    const HISTORY_OF_PRESENT_ILLNESS = Item.SOAP_NOTE_DICT.M.HISTORY_OF_PRESENT_ILLNESS.S
    const PAST_MEDICAL_HISTORY = Item.SOAP_NOTE_DICT.M.PAST_MEDICAL_HISTORY.S
    const PAST_SURGICAL_HISTORY = Item.SOAP_NOTE_DICT.M.PAST_SURGICAL_HISTORY.S
    const FAMILY_HISTORY = Item.SOAP_NOTE_DICT.M.FAMILY_HISTORY.S
    const SOCIAL_HISTORY = Item.SOAP_NOTE_DICT.M.SOCIAL_HISTORY.S
    const REVIEW_OF_SYSTEMS = Item.SOAP_NOTE_DICT.M.REVIEW_OF_SYSTEMS.S
    const ALLERGIES = Item.SOAP_NOTE_DICT.M.ALLERGIES.S
    const VITALS = Item.SOAP_NOTE_DICT.M.VITALS.S
    const PLAN = Item.SOAP_NOTE_DICT.M.PLAN.S
    const completedSoapNoteDict = {
      "CHIEF_COMPLAINT": CHIEF_COMPLAINT,
      "HISTORY_OF_PRESENT_ILLNESS": HISTORY_OF_PRESENT_ILLNESS,
      "PAST_MEDICAL_HISTORY": PAST_MEDICAL_HISTORY,
      "PAST_SURGICAL_HISTORY": PAST_SURGICAL_HISTORY,
      "FAMILY_HISTORY": FAMILY_HISTORY,
      "SOCIAL_HISTORY": SOCIAL_HISTORY,
      "REVIEW_OF_SYSTEMS": REVIEW_OF_SYSTEMS,
      "ALLERGIES": ALLERGIES,
      "VITALS": VITALS,
      "PLAN": PLAN
    }
    return completedSoapNoteDict
  }

  const parsePatientName = (Item) => {
    return Item.PATIENT_NAME.S
  }

  const showCopyIndicatorFor = (SECTION) => {
    setSectionWhichWasCopiedMomentsAgo(SECTION)
 
     setTimeout(()=>{
       setSectionWhichWasCopiedMomentsAgo("")
     }, 3000)
   } 
  // #endregion

  // #region Event handlers
  const handleRecordingButtonClick = ()=>{
    navigate('/transcription-process')
  };

  const handleStartNewSessionButtonClick = ()=>{
    setCurrentTranscriptionPage(TRANSCRIPTION_PAGE_ENUM.SESSION_INFO)
    navigate('/transcription-process')
  }
  // #endregion
  
  // #region Navbar logic
  const auth = getAuth();
  const [profileDropdownIsOpen, setProfileDropdownIsOpen] = useState(false);
  const handleSignOutClick = ()=>{
    signOut(auth).then(() => {
      // console.log('Sign out successful');
      navigate('/');
    }).catch((error) => {
      // console.error('Sign out error', error);
    });    
  };

  const handleNotesButtonClick = ()=>{
    navigate('/notes')
  }
  // #endregion

  // #region useEffect
  useEffect(()=>{
    if (Object.keys(soapNoteDict).length > 0) {
      // console.log("Data already loaded");
      return 
    }
    fetchSoapNoteDictAndPatientName(soapNoteId).then((completedSoapNoteDict)=>{
      setSoapNoteDict(completedSoapNoteDict)
      setLoading(false)
    })
  }, [])
  // #endregion

  return {
    handleCopyClickFor,
    handleNotesButtonClick,
    handleRecordingButtonClick,
    handleSignOutClick,
    handleStartNewSessionButtonClick,
    loading, 
    patientName,
    profileDropdownIsOpen, setProfileDropdownIsOpen,
    sectionWhichWasCopiedMomentsAgo,
    soapNoteDict
    };
    
};
    
export default useTpSoapNotePageVM
