import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../UtilityComponents/GlobalContextProvider";
import { doc, getDoc } from 'firebase/firestore'
import { db } from "../../Configs/firebaseConfig";
import { checkIfUserCanAccessSubscriberServices, calculateProratedRefundAmount, generateDateStringInFormatMMDDYYYYFromDate } from "../../UtilityFunctions/utils";
import { toast } from "sonner";

const useManageSubscriptionPageVM = () => {

  // #region Context
  const { user } = useContext(GlobalContext);
  // #endregion


  // #region Properties
  const [isLoadingPageData, setIsLoadingPageData] = useState(true);
  const [subscriptionEndDateAsString, setSubscriptionEndDateAsString] = useState(Date.now()+1000000000);
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [cancelSubscriptionConfirmationDialogIsPresented, setCancelSubscriptionConfirmationDialogIsPresented] = useState(false);
  const [cancelSubscriptionOperationsAreInProgress, setCancelSubscriptionOperationsAreInProgress] = useState(false);
  const [subscriptionSuccessfullyCanceledDialogIsPresented, setSubscriptionSuccessfullyCanceledDialogIsPresented] = useState(false);
  const [subscribeOperationsAreInProgress, setSubscribeOperationsAreInProgress] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [refundAmount, setRefundAmount] = useState(74);
  const [subscriptionCancellationFailedMessageIsPresented, setSubscriptionCancellationFailedMessageIsPresented] = useState(false);
  // #endregion


  // #region Button Handlers
  const handleCancelSubscriptionButtonClick = async() => {
    setCancelSubscriptionConfirmationDialogIsPresented(true);
  };

  const handleConfirmCancellationButtonClick = async()=>{
    setCancelSubscriptionOperationsAreInProgress(true);
    try{
      console.log("subscriptionId", subscriptionId);
      console.log("user.email", user.email);
      await axios.post(
        `https://0ifjlxoj1j.execute-api.us-east-2.amazonaws.com/cancel_stripe_subscription_mmw?subscriptionId=${subscriptionId}&email=${user.email}`
      )
      // console.log("response.data", response.data);
      setIsSubscriber(false);
      setCancelSubscriptionConfirmationDialogIsPresented(false);
      setCancelSubscriptionOperationsAreInProgress(false);
      setSubscriptionSuccessfullyCanceledDialogIsPresented(true);
    } catch (error) {
      setCancelSubscriptionOperationsAreInProgress(false);
      setSubscriptionCancellationFailedMessageIsPresented(true);
      toast.error("An error occurred. Please try again later.")
    }    
  }


  const handleNoGoBackButtonClick = () => {
    setCancelSubscriptionConfirmationDialogIsPresented(false);
  };

  const handleSubscribeButtonClick = async ()=>{
    setSubscribeOperationsAreInProgress(true);
    try{
      // console.log("Subscribe button clicked");
      const response = await axios.post("https://0cwg0v1he3.execute-api.us-east-2.amazonaws.com/create_checkout_session_on_stripe_hosted_page")
      // console.log("response.data", response.data);
      window.location.href = response.data.url;
    } catch (error) {
      toast.error("An error occurred. Please try again later.")
      setSubscribeOperationsAreInProgress(false);
      //TODO: Handle this error
    }
  }

  const handleOkayButtonClick = () => {
    setSubscriptionSuccessfullyCanceledDialogIsPresented(false)
  }

  const handleOkButtonClickOnSubscriptionCancellationFailedMessage = () => {
    setCancelSubscriptionConfirmationDialogIsPresented(false);
    setSubscriptionCancellationFailedMessageIsPresented(false);
  }
  // #endregion
  

  // #region useEffect
  useEffect(()=>{
    const fetchAndSetDataNeededForPage = async()=>{
      const userDoc = await getDoc(doc(db,"Users",user.email));
      const isSubscribed = userDoc.data().isSubscribed;
      const currentSubscriptionPeriodEnd = userDoc.data().currentSubscriptionPeriodEnd;
      if (!isSubscribed){
        setIsSubscriber(false);
        setIsLoadingPageData(false);
        return;
      }

      if (isSubscribed && currentSubscriptionPeriodEnd){
        const subscriptionEndDateAsString = currentSubscriptionPeriodEnd.toDate().toLocaleDateString();
        setSubscriptionEndDateAsString(subscriptionEndDateAsString);
      }
      const refundAmount = calculateProratedRefundAmount(currentSubscriptionPeriodEnd);
      const fetchedSubscriptionId = userDoc.data().subscriptionId;

      setIsSubscriber(isSubscribed);
      setSubscriptionId(fetchedSubscriptionId);
      setRefundAmount(refundAmount);
      setIsLoadingPageData(false);
    }
    fetchAndSetDataNeededForPage();
  },[user.email])
  // #endregion


  // #region Return
  return {

    cancelSubscriptionOperationsAreInProgress,

    cancelSubscriptionConfirmationDialogIsPresented,

    handleCancelSubscriptionButtonClick,

    handleNoGoBackButtonClick,

    handleConfirmCancellationButtonClick,

    handleOkayButtonClick,

    handleOkButtonClickOnSubscriptionCancellationFailedMessage,

    handleSubscribeButtonClick,


    isLoadingPageData,

    isSubscriber,

    refundAmount,

    subscriptionCancellationFailedMessageIsPresented,

    subscribeOperationsAreInProgress,

    subscriptionSuccessfullyCanceledDialogIsPresented,

    subscriptionEndDateAsString,

  }
  // #endregion

}

export default useManageSubscriptionPageVM;