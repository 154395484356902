import './useLandingPage2VM'
import './LandingPage2.css'

import {anxiety, depression, mania, psychosis, maniaEncounterTranscript } from './LandingPage2Constants'
import heroBgImg from '../../Images/heroBgImg.jpg';
import footerBg from '../../Images/footerBg.jpg';
import chevronLeft from '../../Images/chevronLeft.png';
import chevronRight from '../../Images/chevronRight.png';
import chevronSquareLeft from '../../Images/chevronSquareLeft.png';
import chevronSquareRight from '../../Images/chevronSquareRight.png';
import filledCircle from '../../Images/filledCircle.png';
import strokedCircle from '../../Images/strokedCircle.png';
import menu from '../../Images/menu.svg';
import close from '../../Images/close.svg';
import { useNavigate } from 'react-router-dom';
import glassyBgLoggedIn from '../../Images/glassyBgLoggedIn.jpg';
import { Helmet } from 'react-helmet';
import { useEffect, useMemo, useRef, useState } from 'react';
import useTypingEffect from '../../Reusable/CustomHooks/useTypingEffect';
import browserTabBarImg from '../../Images/browserTabBarImg.jpg';
import Siriwave from 'react-siriwave';




export default function LandingPage2() {

  // #region Properties
  const demoItems = [
    mania, 
    depression, 
    anxiety, 
    psychosis
  ]
  const PLAYING_AMPLITUDE = 1; // Amplitude when video is playing
  const PAUSED_AMPLITUDE = 0.1; // Amplitude when video is paused

  const [isMuted, setIsMuted] = useState(true);
  const demoSectionRef = useRef(null);
  const [demoItemIndex, setDemoItemIndex] = useState(0)
  const currentDemoItem = demoItems[demoItemIndex]
  const [currentTranscript, setCurrentTranscript] = useState('');
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [amplitude, setAmplitude] = useState(PAUSED_AMPLITUDE);
  const [amplitudeInitCount, setAmplitudeInitCount] = useState(0);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const sourceRef = useRef(null);
  const isAudioContextClosed = useRef(false);
  const maniaEncounterVidUrl = 'https://mm-web-media-5a35ec8ea2334a1d97fb81975f134477.s3.us-east-2.amazonaws.com/maniaEncounterVid.mp4'
  const transcriptRef = useRef(null);
  const siriwaveRef = useRef(null);
  const [userInteracted, setUserInteracted] = useState(false);




  const [demoEncounterIsVisible, setDemoEncounterIsVisible] = useState(true)

  const [soapNoteIsVisible, setSoapNoteIsVisible] = useState(false);
  const [startTypingCC, setStartTypingCC] = useState(false);
  const [startTypingHPI, setStartTypingHPI] = useState(false);
  const [startTypingPMH, setStartTypingPMH] = useState(false);
  const [startTypingFH, setStartTypingFH] = useState(false);
  const [startTypingSH, setStartTypingSH] = useState(false);
  const [startTypingROS, setStartTypingROS] = useState(false);
  const [startTypingASMT, setStartTypingASMT] = useState(false);
  const [startTypingPLAN, setStartTypingPLAN] = useState(false);

  const typingSpeed = 20;//milliseconds per character -- greater value means slower typing
  // #endregion


  // #region Methods
  const handleSiriWaveInit = (siriwaveInstance) => {
    siriwaveRef.current = siriwaveInstance;
  };

  function decrementDemoItem(){
    const updatedIndex = (demoItemIndex - 1 + demoItems.length) % demoItems.length
    // console.log(updatedIndex)
    setDemoItemIndex(updatedIndex)
  }

  function incrementDemoItem(){
    const updatedIndex = (demoItemIndex + 1) % demoItems.length
    setDemoItemIndex(updatedIndex)
  }
  // #endregion


  // #region Button Handlers
    const handleCreateAccountButtonClick = () => {
      navigate('/create-account')
    }
  
    const handleGetStartedForFreeButtonClick = () =>{
      navigate('/create-account')
    }
  
    const handleSignInButtonClick = () => {
      navigate('/sign-in')
    }

    const handleSkipToEncounterNotesBeingMade = () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }

      setDemoEncounterIsVisible(false);
      setTimeout(() => {
        setSoapNoteIsVisible(true);
        setTimeout(() => {
          setStartTypingCC(true); // Start typing the first section (Chief Complaint)
        }, 100); // Delay before starting the typing
      }, 700); // Duration of the fade-out animation
    };
    // #endregion
  
  
  // #region Subcomponent(s)
  const ListView = (content) => {
    const items = content.split('\n').filter(line => line.trim());
    return (
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item.trim()}</li>
        ))}
      </ul>
    );
  }

  const chiefComplaintText = useTypingEffect(currentDemoItem?.Chief_Complaint?.content.trim() || '', startTypingCC, typingSpeed);
  const hpiText = useTypingEffect(currentDemoItem?.History_Of_Present_Illness?.content.trim() || '', startTypingHPI, typingSpeed);
  const pmhText = useTypingEffect(currentDemoItem?.Past_Medical_History?.content.trim() || '', startTypingPMH, typingSpeed);
  const fhText = useTypingEffect(currentDemoItem?.Family_History?.content.trim() || '', startTypingFH, typingSpeed);
  const shText = useTypingEffect(currentDemoItem?.Social_History?.content.trim() || '', startTypingSH, typingSpeed);
  const rosText = useTypingEffect(currentDemoItem?.Review_of_Systems?.content.trim() || '', startTypingROS, typingSpeed);
  const asmtText = useTypingEffect(currentDemoItem?.Assessment?.content.trim() || '', startTypingASMT, typingSpeed);
  const planText = useTypingEffect(currentDemoItem?.Plan?.content.trim() || '', startTypingPLAN, typingSpeed);


  const videoElement = useMemo(() => (
    <video
      ref={videoRef}
      className="video-player absolute inset-0 md:w-[450px] w-full h-full"
      src={maniaEncounterVidUrl}
      controls
      preload="auto"
      crossOrigin="anonymous"
      muted={isMuted}
    />
  ), []);  

  const memoizedSiriWave = useMemo(() => (
    <Siriwave
      amplitude={amplitude}
      height={70}
      speed={0.3}
      style={{marginTop: '20px'}}
      width={150}
    />
  ), [amplitude]);
  // #endregion


  // #region useEffects
  // Check if the user has interacted with the page
  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true);
    };

    document.addEventListener('click', handleUserInteraction, { once: true });
    document.addEventListener('keydown', handleUserInteraction, { once: true });

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };
  }, []);


  // Starts the video when the demo section is visible
  useEffect(() => {
    if (soapNoteIsVisible) return;

    const videoElement = videoRef.current;
    const demoSection = demoSectionRef.current;

    const observerOptions = {
      root: null, // Observe within the viewport
      rootMargin: '0px',
      threshold: 0.5 // Adjust as needed; 0.5 means 50% of the element is visible
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          console.log("Playing video");
          videoElement.play();
        } else {
          console.log("Pausing video");
          videoElement.pause();
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    if (demoSection) {
      observer.observe(demoSection);
    }

    return () => {
      if (demoSection) {
        observer.unobserve(demoSection);
      }
    };
  }, [soapNoteIsVisible]);


  // Effect for handling SiriWave amplitude based on play/pause state
  useEffect(() => {
    const videoElement = videoRef.current;

    const handlePlay = () => {
      setAmplitude(PLAYING_AMPLITUDE);
    };

    const handlePause = () => {
      setAmplitude(PAUSED_AMPLITUDE);
    };

    // Attach event listeners
    videoElement.addEventListener('play', handlePlay);
    videoElement.addEventListener('pause', handlePause);

    // Cleanup event listeners on component unmount
    return () => {
      videoElement.removeEventListener('play', handlePlay);
      videoElement.removeEventListener('pause', handlePause);
    };
  }, []);


  // Effect for updating transcript without affecting SiriWave amplitude
  useEffect(() => {
    const videoElement = videoRef.current;

    const updateTranscript = () => {
    
      // Find all text up to the current time
      const accumulatedText = maniaEncounterTranscript
        .filter(t => videoElement.currentTime >= t.start)
        .map(t => t.text)
        .join(' ');

      // Update the state with the accumulated text
      setCurrentTranscript(accumulatedText);
    };

    // Attach event listener to update transcript on time update
    videoElement.addEventListener('timeupdate', updateTranscript);

    // Cleanup event listener on component unmount
    return () => {
      videoElement.removeEventListener('timeupdate', updateTranscript);
    };
  }, [maniaEncounterTranscript]); // Dependency on the transcript   


  // Scroll to the bottom of the transcript ref
  useEffect(() => {
    // Scroll to the bottom of the transcript div whenever the transcript updates
    if (transcriptRef.current) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [currentTranscript]);


  // Soap note typing effect
  useEffect(() => {
    if (startTypingCC && chiefComplaintText.length === currentDemoItem.Chief_Complaint.content.trim().length) {
      setStartTypingHPI(true);
    }
  }, [startTypingCC, chiefComplaintText, currentDemoItem]);

  useEffect(() => {
    if (startTypingHPI && hpiText.length === currentDemoItem.History_Of_Present_Illness.content.trim().length) {
      setStartTypingPMH(true);
    }
  }, [startTypingHPI, hpiText, currentDemoItem]);

  useEffect(() => {
    if (startTypingPMH && pmhText.length === currentDemoItem.Past_Medical_History.content.trim().length) {
      setStartTypingFH(true);
    }
  }, [startTypingPMH, pmhText, currentDemoItem]);

  useEffect(() => {
    if (startTypingFH && fhText.length === currentDemoItem.Family_History.content.trim().length) {
      setStartTypingSH(true);
    }
  }, [startTypingFH, fhText, currentDemoItem]);

  useEffect(() => {
    if (startTypingSH && shText.length === currentDemoItem.Social_History.content.trim().length) {
      setStartTypingROS(true);
    }
  }, [startTypingSH, shText, currentDemoItem]);

  useEffect(() => {
    if (startTypingROS && rosText.length === currentDemoItem.Review_of_Systems.content.trim().length) {
      setStartTypingASMT(true);
    }
  }, [startTypingROS, rosText, currentDemoItem]);

  useEffect(() => {
    if (startTypingASMT && asmtText.length === currentDemoItem.Assessment.content.trim().length) {
      setStartTypingPLAN(true);
    }
  }, [startTypingASMT, asmtText, currentDemoItem]);

  useEffect(() => {
    if (startTypingPLAN && planText.length === currentDemoItem.Plan.content.trim().length) {
      setStartTypingPLAN(false);
    }
  }, [startTypingPLAN, planText, currentDemoItem]);

  // #endregion


  return (
    <div className='flex flex-col'>

      <Helmet>
        <title>Magic Medic AI - Automated Notes for Mental Health Professionals</title>
        <meta name="description" content="Automated notes for mental health professionals. Magic Medic AI helps take notes during therapy sessions." />
        <meta name="keywords" content="mental health, therapy, AI, scribe, automated notes, Soap Note" />
        <meta property="og:title" content="Magic Medic AI" />
        <meta property="og:description" content="Automated notes for mental health professionals." />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
        <meta property="og:url" content="https://www.magicmedicai.com" />
      </Helmet>

      {/* HeroSection */}
      <div id="HeroSection" className="animate-fadeIn relative bg-gray-800 md:h-[890px] h-[800px] px-16 justify-center flex">
        <div id="wide-card-zstack" className="absolute bg-gray-800 md:w-[85vw] w-[90vw] md:min-w-[600px] max-w-[900px] md:h-[710px] h-[640px] flex items-center justify-center mt-8 rounded-[4vw] overflow-hidden">

          {/* Card Image */}
          <img src={glassyBgLoggedIn} alt="heroBgImg" className="absolute inset-0 w-full h-full object-cover" />

          {/* Hero Section Content */}
          <div id="hero-section-content" className="absolute top-0 w-full h-full p-8">
            <div id="hero-section-col" className="flex flex-col h-full space-y-16">

              <nav id="nav-bar" className="animate-fadeIn flex items-center justify-between w-full">
                <p className="text-white md:text-xl font-bold text-md">Magic Medic AI</p>
                
                <div id="right-side-of-navbar" className="flex flex-row font-semibold items-center text-sm text-white space-x-4">
                  <a href="#demo" className="text-white hover:opacity-50">Demo</a>
                  
                  <a href="#pricing" className="text-white hover:opacity-50">Pricing</a>
                  
                  <button id="sign-in-button" className="relative px-8 py-2 bg-gray-900 rounded-3xl hover:opacity-50"
                    onClick={handleSignInButtonClick}
                  >
                    <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
                    <p className="text-[#A343FF] relative">Sign in</p>
                  </button>
                </div>


              </nav>

              <div id='hero-message-and-get-started-button-col' className='space-y-4'>
                <h1 className="md:text-5xl text-3xl font-bold md:leading-[100px] leading-[80px] text-white">
                  An AI virtual scribe that<br/>completes your patient<br/>encounter notes for you <br/>within minutes
                  <br/>
                </h1>
                <h1 className="animate-fadeIn text-xl font-semibold leading-[50px] text-white">
                  Are you a psychiatrist or a therapist?
                </h1>

                <button id="get-started-button" className="relative px-8 py-3 bg-gray-900 w-50 rounded-3xl hover:opacity-50 flex-none"
                  onClick={handleGetStartedForFreeButtonClick}
                >
                  <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
                  <p className="text-[#A343FF] font-bold relative">Get started for free</p>
                </button>
              </div>

            </div>

          </div>
        </div>
      </div>

      {/* DemoSection */}
      <div id="demo" ref={demoSectionRef} className="relative bg-gray-800 md:h-[1100px] h-[880px] flex justify-center">
        
        <div id='demo-section-col' className='text-left w-[85vw] md:min-w-[600px] max-w-[900px] flex flex-col'>

          <div id='demo-title' className='text-white md:text-4xl text-3xl font-bold mb-8'> Quick example of how it works</div>
        
          <div id='demo-content-zstack' className='relative flex flex-col space-y-4'>
            
            {/* Example Encounter Section */}
            <div id='example-encounter-col'
              className={`absolute transition-all duration-700 ease-in-out ${demoEncounterIsVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10 pointer-events-none'}`}
              style={{ position: demoEncounterIsVisible ? 'relative' : 'absolute' }}
            >  

              <div id='demo-subtitle' className='text-white md:text-3xl text-2xl font-bold mb-8'>
                An example encounter
              </div>
              
              <div id='vid-and-button-row' className='flex flex-row justify-between mb-8'>
                  <div id="video" className="relative md:w-[50vw] w-[500px] md:h-[200px] h-[200px]">
                    {videoElement}
                  </div>
              </div>

              <button id="skip-to-encounter-note-being-made-button"
                className='relative px-8 py-3 bg-gray-900 w-full md:w-[450px] rounded-3xl hover:opacity-50 flex-none mb-8'
                onClick={handleSkipToEncounterNotesBeingMade}
              >
                <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
                <p className="text-[#A343FF] font-bold relative">Skip to the encounter note being made</p>
              </button>          

              <div id='demo-mini-browser'
                className="relative w-full md:w-[500px] h-[380px] md:h-[500px] bg-cover bg-center"
                style={{ backgroundImage: `url(${glassyBgLoggedIn})`}}
              >
                <img src={browserTabBarImg} alt='browser-tab-bar-img'/>
                <div id='demo-browser-content' className='flex flex-col items-center'>
                  <div id='MM-title' className='font-semibold text-xs text-white mt-2'>
                    Magic Medic AI
                  </div>

                  {memoizedSiriWave}

                  {/* Transcript here */}
                  <div id='transcript' ref={transcriptRef} className='font-medium text-[14px] text-white mt-4 w-3/4 h-48 p-2 overflow-y-auto'>
                    {currentTranscript}
                  </div>
                  

                </div>
              </div>    
              
            </div>

            {/* Encounter SOAP Note Section */}
            <div id='soap-note'
              className={`relative md:w-[52vw] w-[86vw] h-[500px] overflow-y-scroll py-1 pr-3 space-y-12 text-white transition-all duration-700 ease-in-out ${soapNoteIsVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
            >
              <div id='cc' className='flex flex-col space-y-2 text-left'>
                <p className='text-xl font-bold'>{currentDemoItem.Chief_Complaint.title}</p>
                <p className='text-lg opacity-50 font-medium leading-10'>
                  {chiefComplaintText}
                </p>
              </div>

              <div id='hpi' className='flex flex-col space-y-2 text-left'>
                <p className='text-xl font-bold'>{currentDemoItem.History_Of_Present_Illness.title}</p>
                <p className='text-lg opacity-50 font-medium leading-10'>
                  {hpiText}
                </p>
              </div>

              <div id='pmh' className='flex flex-col space-y-2 text-left'>
                <p className='text-xl font-bold'>{currentDemoItem.Past_Medical_History.title}</p>
                <div className='text-lg text opacity-50 font-medium leading-10'>
                  {ListView(pmhText)}
                </div>
              </div>

              <div id='fh' className='flex flex-col space-y-2 text-left'>
                <p className='text-xl font-bold'>{currentDemoItem.Family_History.title}</p>
                <p className='text-lg opacity-50 font-medium leading-10'>
                  {ListView(fhText)}
                </p>
              </div>

              <div id='sh' className='flex flex-col space-y-2 text-left'>
                <p className='text-xl font-bold'>{currentDemoItem.Social_History.title}</p>
                <div className='text-lg opacity-50 font-medium leading-10'>
                  {ListView(shText)}
                </div>
              </div>

              <div id='ros' className='flex flex-col space-y-2 text-left'>
                <p className='text-xl font-bold'>{currentDemoItem.Review_of_Systems.title}</p>
                <div className='text-lg opacity-50 font-medium leading-10'>
                  {ListView(rosText)}
                </div>
              </div>

              <div id='asmt' className='flex flex-col space-y-2 text-left'>
                <p className='text-xl font-bold'>{currentDemoItem.Assessment.title}</p>
                <div className='text-lg opacity-50 font-medium leading-10'>
                  {ListView(asmtText)}
                </div>
              </div>

              <div id='pl' className='flex flex-col space-y-2 text-left'>
                <p className='text-xl font-bold'>{currentDemoItem.Plan.title}</p>
                <p className='text-lg opacity-50 font-medium leading-10'>
                  {ListView(planText)}
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      
      {/* PricingSection */}
      <div id="pricing" className="relative bg-gray-800 h-[660px] flex justify-center">
        <div id="pricing-section-col" className='md:w-[85vw] w-[90vw] md:min-w-[600px] max-w-[900px]'>
          <h1 className="text-white text-4xl font-bold mb-8">Pricing</h1>
          <div id="wide-card-zstack" className="relative bg-gray-800 w-full h-[500px] flex items-center justify-center mt-8 rounded-[4vw] overflow-hidden">
            <img src={glassyBgLoggedIn} alt="heroBgImg" className="absolute inset-0 w-full h-full object-cover" />
            {/* <div id="glassy" className="absolute inset-0 bg-[#36085D] bg-opacity-60 backdrop-blur-md"/> */}
            <div id="pricing-section-content" className="absolute w-full p-8">
              <div id="hero-section-row" className="flex flex-row justify-between">
                {/* <div id="pricing-message" className="text-white flex flex-col font-bold justify-between py-4">
                  <p className="text-white text-xl sm:text-2xl font-bold">Affordable pricing</p>
                  <p className="text-white text-xl sm:text-2xl font-bold">and automated notes</p>
                  <p className="text-white text-xl sm:text-2xl font-bold">means more patients</p>
                  <p className="text-white text-xl sm:text-2xl font-bold">you're able to help</p>
                </div> */}
                <div id='right-side-zstack' className="relative">
                  <div id="pricing-gray-rect" className="relative w-[70vw] max-w-[500px] h-[350px] bg-gray-800 opacity-0 rounded-[24px]"/>
                  <div id="pricing-right-side-col" className="absolute inset-0 flex flex-col justify-between items-start py-4 pl-4 space-y-4" >
                    <button id="get-started-button" className="relative px-8 py-3 bg-gray-900 w-50 rounded-3xl hover:opacity-50 flex-none"
                      onClick={handleGetStartedForFreeButtonClick}
                    >
                      <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
                      <p className="text-[#A343FF] font-bold relative">Get started for free</p>
                    </button>
                    <p className="text-white text-xl font-bold top-0">10 free encounter notes, no credit card required</p>
                    <p className="text-white text-xl font-bold top-0">Unlimited notes with subscription</p>
                    {/* <p className="text-white text-xl font-bold top-0">60-day money back guarantee</p> */}
                    <p className="text-white text-xl font-bold top-0">HIPAA compliant</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FooterSection */}
      <div id='FooterSection' className='relative h-[150px]'>
        <img src={footerBg} alt="footerBg" className="absolute w-full h-full object-cover" />
        <div id='footer-section-content' className="absolute w-full flex flex-row justify-between sm:text-[8px] md:text-[9px] lg:text-[10px] text-[6px] px-16 py-8">

          <div id='magic-medic-ai-footer-col' className="font-medium px-1 space-y-2 text-left text-white">
            <div className="font-semibold">Magic Medic AI</div>
            <div className="text-opacity-50 text-white">
              Copyright © 2024<br />
              All rights reserved<br />
              support@magicmedicai.com<br />
            </div>
          </div>

          <div id='security-footer-col' className="font-medium space-y-2 text-left text-white">

            <div>Security</div>
            <div className="text-opacity-50 text-white">

              <div className='flex flex-row space-x-1'>
                <p>
                  •
                </p>
                <p>
                  HIPAA compliant
                </p>
              </div>
              <div className='flex flex-row space-x-1'>
                <p>
                  •
                </p>
                <p>
                  You can delete<br />
                  your data any<br />
                  time  you wish
                </p>
              </div>

            </div>
          </div>

          <div id='houston-office-footer-col' className="font-medium space-y-2 text-left text-white">
            <div>Houston Office</div>
            <div className="text-opacity-50 text-white">
              4201 Main St,<br />
              Houston, TX 77002
            </div>
          </div>

          <div id='delaware-office-footer-col' className="font-medium space-y-2 text-left text-white">
            <div>Delaware Office</div>
            <div className="text-opacity-50 text-white">
              251 Little Falls Drive,<br />
              Wilmington, Delaware 19808
            </div>
          </div>

        </div>
      </div>

    </div>
  );
}
