import { getAuth, signOut } from "firebase/auth";
import PATIENT_SEX_ENUM from "../../Enums/PATIENT_SEX_ENUM";
import TRANSCRIPTION_PAGE_ENUM from "../../Enums/TRANSCRIPTION_PAGE_ENUM";
import CUSTOMER_TYPE_ENUM from "../../Enums/CUSTOMER_TYPE_ENUM.js";
import { TranscriptionProcessContext } from '../Transcription_Process_MultiPage/TranscriptionProcessContext.jsx'
import { GlobalContext } from '../../UtilityComponents/GlobalContextProvider'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
  getCustomerType,
  generateFormattedDateString,
  generateUUID,
  getIso8601Timestamp,
} from '../../UtilityFunctions/utils'
import AWS from 'aws-sdk';
import awsConfig from '../../Configs/awsConfig.js'; 
import { doc, getDoc, getDocs, setDoc, Timestamp, collection, query, where } from 'firebase/firestore';
import { db } from '../../Configs/firebaseConfig';
import { toast } from "sonner";



// #region AWS setup
// I'm initializing these AWS services outside of the hook
// to avoid re-creating it on every render.
AWS.config.update({
  region: awsConfig.s3.region,
  accessKeyId: awsConfig.s3.accessKeyId,
  secretAccessKey: awsConfig.s3.secretAccessKey,
});
var ddb = new AWS.DynamoDB({ apiVersion: "2012-08-10" });

// #endregion AWS setup

const useSessionInfoPageVM = () => {

  // #region Contexts
  const { user } = useContext(GlobalContext)

  const {
    currentTranscriptionPage, setCurrentTranscriptionPage,
    patientName, setPatientName,
    patientAge, setPatientAge,
    patientSex, setPatientSex,
    setSoapNoteId,
  } = useContext(TranscriptionProcessContext)
  // #endregion


  // #region Properties
  const [customerType, setCustomerType] = useState(CUSTOMER_TYPE_ENUM.CURRENTLY_FETCHING_CUSTOMER_TYPE)
  const [isLoadingPageData, setIsLoadingPageData] = useState(true)
  const [errors, setErrors] = useState({
    patientName: '',
    patientAge: '',
    patientSex: '',
  });
  const [toastIsPresented, setToastIsPresented] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [notesCreatedCount, setNotesCreatedCount] = useState(0);
  const navigate = useNavigate();
  // #endregion


  // #region Methods
  const validateInputs = () => {
    // // console.log("validateInputs called");
    let newErrors = { ...errors }; // Copy existing errors
    let isValid = true; // Flag to track if all inputs are valid

    // Validate patient name
    if (!patientName) {
      newErrors.patientName = 'Patient name cannot be empty';
      isValid = false;
    } else {
      newErrors.patientName = '';
    }

    // Validate patient age
    if (patientAge <= 0 || patientAge > 122) {
      newErrors.patientAge = 'Please enter a valid age';
      isValid = false;
    } else {
      newErrors.age = '';
    }

    // Validate patient sex
    if (patientSex !== PATIENT_SEX_ENUM.male 
      && patientSex !== PATIENT_SEX_ENUM.female
    ) {
      newErrors.patientSex = 'Please enter a valid sex';
      isValid = false;
    } else {
      newErrors.patientSex = '';
    }
    
    setErrors(newErrors); // Update the errors state
    return isValid;
  }

  const generateSoapNoteId = () => {
    const uuid = generateUUID();
    const dateString = generateFormattedDateString();
    const patientAgeString = patientAge.toString();
    const firstLetterOfPatientSex = patientSex.charAt(0).toUpperCase();
    const soapNoteId = dateString+patientAgeString+firstLetterOfPatientSex+'_'+uuid 
    return soapNoteId
  }

  const uploadItemToDynamoDB = async () => {
    // console.log("uploadItemToDynamoDB called");
    const soapNoteId = generateSoapNoteId()
    setSoapNoteId(soapNoteId)

    const patientAgeString = patientAge.toString();
    const trimmedPatientName = patientName.trim();
    const timestamp = getIso8601Timestamp();

    const params = {
      TableName: "SOAP_NOTES_TABLE",
      Item: {
        "SOAP_NOTE_ID": { S: soapNoteId },
        "PATIENT_NAME": { S: trimmedPatientName },
        "PATIENT_AGE": { S: patientAgeString },
        "PATIENT_SEX": { S: patientSex },
        "USER_ID": { S: user.uid },
        "DDB_TIMESTAMP": { S: timestamp }
      }
    }

    try {
      const result = await ddb.putItem(params).promise();
      // console.log(result)
      // console.log('Successfully uploaded item to DynamoDB');
      return true
    } catch (error) {
      //TODO: Handle this error
      // console.error('Failed to add item to db because of', error);
      return false
    }
  }

  const getUserDocFromFirestoreUsingUserEmail = async (userEmail) =>{
    //check for internet
    // Display some form of error message to indicate to the user to reconnect.
    const userDoc = await getDoc(doc(db, "Users", userEmail))
    if (!userDoc.exists()){
      navigate('/sign-in')
      return;
    }
    return userDoc
  }

  const getNotesCreatedValueFromUserDoc = async (userDoc)=>{
    if (!userDoc.exists()){
      // Something is very wrong, handle the error
      // console.error("User document does not exist");
      //TODO: Handle this error
      navigate('/sign-in')
      return;
    }
    const notesCreated = userDoc.data()["notesCreated"]
    return notesCreated
  }

  const getIsSubscribedValueFromUserDoc = async (userDoc)=>{
    if (!userDoc.exists()){
      // Something is very wrong, handle the error
      // console.error("User document does not exist");
      //TODO: Handle this error
      navigate('/sign-in')
      return;
    }
    const isSubscribed = userDoc.data()["isSubscribed"]
    return isSubscribed
  }
  // #endregion


  // #region Button Handlers
  const handleStartSessionButtonClick = async()=>{
    const isValid = validateInputs();
    if (!isValid) return; 
    const soapNoteId = generateSoapNoteId();
    setSoapNoteId(soapNoteId);

    if (!navigator.onLine){
      toast.error('Please check your internet connection and try again');
      // Set the toast stuff here
      return;
    }

    setCurrentTranscriptionPage(TRANSCRIPTION_PAGE_ENUM.RECORDING_SESSION)
  };

  const handleManageSubscriptionClick = () => {
    navigate('/manage-subscription');
  }
  // #endregion


  // #region UseEffects
  useEffect(() => {
    setPatientAge('');
    setPatientName('');
    setPatientSex(PATIENT_SEX_ENUM.none_selected);
  }, [setPatientAge, setPatientName, setPatientSex]);


  useEffect(() => {
    const fetchNotesCreatedCount = async () => {
      try {
        const userDoc = await getUserDocFromFirestoreUsingUserEmail(user.email);
        const fetchedNotesCreatedcount = await getNotesCreatedValueFromUserDoc(userDoc);
        const isSubscribed = await getIsSubscribedValueFromUserDoc(userDoc);
        const customerType = getCustomerType(fetchedNotesCreatedcount, isSubscribed);
        // console.log("customerType", customerType);
  
        setNotesCreatedCount(fetchedNotesCreatedcount);
        setCustomerType(customerType);
        setIsLoadingPageData(false);
      } catch (error) {
        toast.error('Please check your internet connection and try again')
      }
    };
    fetchNotesCreatedCount();
  }, [user.email]);
  // #endregion
    
  return {

    customerType,

    errors,

    handleManageSubscriptionClick,

    handleStartSessionButtonClick,
    
    isLoadingPageData,

    notesCreatedCount,
    
    setPatientAge, patientAge,
    
    setPatientName, patientName, 
    
    setPatientSex, patientSex, 
    
    setCurrentTranscriptionPage, currentTranscriptionPage,

    toastIsPresented, toastMessage

  };
  
}

export default useSessionInfoPageVM;