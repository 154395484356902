import CUSTOMER_TYPE_ENUM from "../../../Enums/CUSTOMER_TYPE_ENUM";
import SessionInfoForm from "./SessionInfoForm";

export default function MainContentBasedOnCustomerType({ viewModel }){
  
  return (
    <>
      {/* User is a paying customer or free trial customer */}
      {(viewModel.customerType === CUSTOMER_TYPE_ENUM.LESS_THAN_10_NC_AND_NOT_SUBSCRIBED || 
        viewModel.customerType === CUSTOMER_TYPE_ENUM.LESS_THAN_10_NC_AND_IS_SUBSCRIBED ||
        viewModel.customerType === CUSTOMER_TYPE_ENUM.GREATER_THAN_10_NC_AND_IS_SUBSCRIBED
      ) && (
        <SessionInfoForm viewModel={viewModel} />
      )}

      {/* Customer has exceeded free trial limit */}
      {viewModel.customerType === CUSTOMER_TYPE_ENUM.GREATER_THAN_10_NC_AND_NOT_SUBSCRIBED && (
        
        
        <div className="flex flex-col items-center space-y-4">
          <h2 className="text-lg text-white">Please sign up for a subscription</h2>
          <h2 className="text-lg text-white">to continue using Magic Medic AI.</h2>
          <button id="subscribe-button" 
            className="relative py-3 bg-gray-900 w-[300px] rounded-3xl hover:opacity-50 flex-none"
            onClick={viewModel.handleManageSubscriptionClick}
          >
            <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
            <p className="text-[#A343FF] font-bold relative">Manage Subscription</p>
          </button>

        </div>
        
      )}      
    </>


  );

}