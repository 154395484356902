const RECORDING_PAGE_ENUM = Object.freeze({
  STARTED: "STARTED", 
  PAUSED: "PAUSED", 
  RESUMED: "RESUMED",
  CLOSE_ATTEMPT: "CLOSE_ATTEMPT",
  DONE_ATTEMPT: "DONE_ATTEMPT",
  UPLOAD_IN_PROGRESS: "UPLOAD_IN_PROGRESS",
  SAVING_IN_PROGRESS: "SAVING_IN_PROGRESS",
  SAVED_SUCCESSFULLY: "SAVED_SUCCESSFULLY",
  ERROR_STATE: "ERROR_STATE",
});

export default RECORDING_PAGE_ENUM;