import React from 'react';
import { useNavigate } from 'react-router-dom';


export default function CanceledPaymentPage() {

  const navigate = useNavigate();
  const handleContinueToSessionInfoPageButtonClick = ()=>{
    navigate('/manage-subscription');
  }

  return (
    <div id="bg-logged-in" className="bg-loggedin flex justify-center h-screen">
      <div className="flex flex-col items-center justify-center space-y-4 text-white">
        <h1>Your payment was canceled</h1>
        <p>
          Please try again or contact support if you need help.
        </p>

        <button id="continue-to-session-info-button" 
          className="relative py-3 bg-gray-900 w-[300px] rounded-3xl hover:opacity-50 flex-none"
          onClick={handleContinueToSessionInfoPageButtonClick}
        >
          <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
          <p className="text-[#A343FF] font-bold relative">Continue</p>
        </button>


      </div>
    </div>
  );
}