import { Navigate, Outlet } from 'react-router-dom';
import React, { useContext } from 'react';
import { GlobalContext } from './GlobalContextProvider';

const ProtectedRoute = () => {
  const { user, loading } = useContext(GlobalContext);

  if (loading) {
    return <div>Loading...</div>;
  }


  return user ? <Outlet /> : <Navigate to='/sign-in'/>;
};
export default ProtectedRoute;
