import whiteMicIcon from '../Images/whiteMicIcon.png';
import whiteNotesIcon from '../Images/whiteNotesIcon.png';
import whiteProfileIcon from '../Images/whiteProfileIcon.png';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export default function Navbar() {
  const navigate = useNavigate();

  const handleManageSubscriptionButtonClick = () => {
    navigate('/manage-subscription');
  };
  const handleNotesButtonClick = () => {
    navigate('/notes');
  };

  const handleRecordingsButtonClick = () => {
    navigate('/transcription-process');
  };

  const handleSignOutButtonClick = () => {
    navigate('/');
  };
  const [profileDropdownIsOpen, setProfileDropdownIsOpen] = useState(false);

  return (
    <div id="navbar-and-divider" className="w-full  sticky top-0 z-10">
      <div id="navbar-logged-in" className="flex flex-row justify-between items-start w-full px-4 pt-4 pb-2 bg-gray-900/40 shadow-md">

        <div id="Magic-Medic-AI-title" className="mt-2 flex items-center">
          <p id="Magic-Medic-AI-title" className="font-bold text-white text-sm">
            Magic Medic AI
          </p>
        </div>

        <div id="nav-buttons-right-side" className="flex flex-row items-center justify-end max-w-screen space-x-4 text-md">
          <div id="recording-button-row">
            <button className="flex flex-row items-center space-x-1 bg-transparent border-none p-2 hover:opacity-50 rounded"
              onClick={handleRecordingsButtonClick}
            >
              <img src={whiteMicIcon} alt="micIcon" className="size-6"/>
              <p className="text-white text-sm font-semibold -mt-1">
                Recording
              </p>
            </button>
          </div>

          <div id="notes-button-div">
            <button className="flex flex-row items-center space-x-1 bg-transparent border-none p-2 hover:opacity-50 rounded"
              onClick={handleNotesButtonClick}
            >
              <img src={whiteNotesIcon} alt="notesIcon" className="size-6"/>
              <p className="text-white text-sm font-semibold -mt-1">
                Notes
              </p>
            </button>
          </div>

          <div id="profile-button-div" className="relative"
            onMouseEnter={() => setProfileDropdownIsOpen(true)}
            onMouseLeave={() => setProfileDropdownIsOpen(false)}
          >
            <button className="flex flex-row items-center space-x-1 bg-transparent border-none p-2 hover:opacity-50 rounded">
              <img src={whiteProfileIcon} alt="profileIcon" className="size-6"/>
              <p className="text-white text-sm font-semibold -mt-1">
                Profile
              </p>
            </button>

            <div className="absolute right-0 w-24 h-3" style={{backgroundColor: 'transparent'}}></div>

            {profileDropdownIsOpen && (
              <div className="absolute bg-[#101827] mt-3 right-0 py-2 w-40 rounded-md shadow-xl text-[#A343FF] text-sm z-20">
                <ul>
                  <li id="sign-out-list-item"
                    className="cursor-pointer hover:opacity-50 font-medium px-4 py-2"
                    onClick={handleSignOutButtonClick}
                  >
                    Sign out
                  </li>
                  <li id="manage-subscription-list-item"
                    className="cursor-pointer hover:opacity-50 font-medium px-4 py-2"
                    onClick={handleManageSubscriptionButtonClick}
                  >
                    Manage subscription
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div id="navbar-divider" className="border-t border-gray-200 my-0"></div>
    </div>
  );
}
