import useCorrectionsPageVM from './useCorrectionsPageVM';

import React, {useState, useEffect, useRef, useContext} from 'react';
import AWS from 'aws-sdk';
import awsConfig from '../../Configs/awsConfig.js'; 
import IndeterminateLoader from '../../UtilityComponents/IndeterminateLoader';
// import { protoWordsDictList, protoWordsToLookAt } from './CorrectionsPageConstants.js';

import darkPurpleCheckmarkCircle from '../../Images/darkPurpleCheckmarkCircle.png'
import darkPurpleThreeCircle from '../../Images/darkPurpleThreeCircle.png'
import BgLoggedIn from '../../ReusableUI/BgLoggedIn.jsx';


AWS.config.update({
  region: awsConfig.s3.region,
  accessKeyId: awsConfig.s3.accessKeyId,
  secretAccessKey: awsConfig.s3.secretAccessKey,
});

var ddb = new AWS.DynamoDB({ apiVersion: "2012-08-10" });



export default function CorrectionsPage () {

    const { 
      // dummyAudioBlobRef,
      // dummyAudioBlobUrl,
      audioBlobRef,
      audioBlobUrl,
      handleDoneButtonClick,
      handleEdit,
      handleMinus5SecondsButtonClick,
      handlePlus5SecondsButtonClick,
      handleWordClick,
      isLoading,
      preventInvalidInput,
      rawWordsDictList,
      wordsToLookAtList    
  } = useCorrectionsPageVM();

  return(
    <div id="bg-logged-in" className="full-height-container bg-cover bg-center h-screen flex flex-col justify-start items-center">

      <BgLoggedIn/>

      <div id="navbar-and-divider" className="w-full absolute top-0">
        <div id="navbar-logged-in" className='flex flex-row justify-center w-full px-8 pt-2 pb-2'>

          <div id="Magic-Medic-AI-title" className=''>
            <p id="Magic-Medic-AI-title" className='font-bold text-white text-sm'>
              Magic Medic AI
            </p>
          </div>
        </div>

        <div id="navbar-divider" className="border-t border-gray-200 my-0"></div>
      </div>

      <div id='stuff-below-the-nav-bar' className='flex flex-col items-center space-y-24 mt-10'>
        <div id='tp-progress-indicator-and-divider' className='w-full absolute'>
            <div id='tp-progress-indicator'  className='flex flex-row justify-center items-center space-x-4 mt-2' >
              
              <div id='info-col' className='flex flex-col items-center space-y-2 mr-4c'>
                <img src={darkPurpleCheckmarkCircle} alt="checkmark-circle" className='size-7'/>
                <p className='text-white text-opacity-50 font-semibold text-sm'>Info</p>
              </div>

              <div id='empty-rounded-rectangle' className='w-16 h-2 bg-[#5E209B] rounded-md -mt-8'/>

              <div id='recording-col' className='flex flex-col items-center space-y-2'>
                <img src={darkPurpleCheckmarkCircle} alt="checkmark-circle"  className='size-7'/>
                <p className='text-white text-opacity-50 font-semibold text-sm'>Recording</p>
              </div>

              <div id='empty-rounded-rectangle' className='w-16 h-2 bg-[#5E209B] rounded-md -mt-8'/>

              <div id='review-col' className='flex flex-col items-center space-y-2'>
                <img src={darkPurpleThreeCircle} alt="threeCircle"  className='size-6'/>
                <p className='text-white font-semibold text-sm'>Review</p>
              </div>

            </div>
            
            <div id="navbar-divider" className="border-t border-gray-200 mt-2"></div>
        </div>


        {isLoading ? (
          <div id="non-navbar-elements-of-page" className='absolute mt-24 flex flex-col space-y-4'>
            <p className='font-semibold text-white text-center'>
              Your SOAP Note will be
            </p>
            <p className='font-semibold text-white text-center'>
              ready in less than 3 minutes
            </p>
            <p className='font-bold text-white text-center'>
              Please do not refresh
            </p>
            <div className='pt-2'>
              <IndeterminateLoader/>
            </div>
          </div>
        ): (
          <div className='flex flex-col items-center justify-center space-y-8'>
            <div id='instruction' className='text-white font-semibold text-sm text-left mb-2'> 
              <div className='flex flex-row space-x-1'>
              <p>
              Please correct the words in
              </p>
              <p className='text-red-600'>
              red
              </p>
              <p>
              (only if necessary).<br/> 
              </p>
              </div>
              Not sure what the word is?<br/> Click on the word then click play to listen to it.
            </div>

            <div className='flex flex-col space-y-2'>
              <audio
                ref={audioBlobRef}
                className="h-10 mx-auto w-[300px]"
                controls 
                src={audioBlobUrl}
              >
                Your browser does not support the audio element.
              </audio>

              <div id='audio-controls' 
                className='flex flex-row w-[300px] justify-between'>
                <button id='minus-5sec-button'
                  className='flex flex-col space-y-2 hover:opacity-50'
                  onClick={handleMinus5SecondsButtonClick}
                >
                <div className='text-white font-bold'>
                  - 5 seconds
                </div>
                </button>

                <button id='plus-5sec-button'
                  className='flex flex-col space-y-2 hover:opacity-50'
                  onClick={handlePlus5SecondsButtonClick}
                >
                <div className='text-white font-bold'>
                  + 5 seconds
                </div>
                </button>
              </div>
            </div>

            <div id = 'mutable-transcript'
              className='font-semibold items-center text-sm
              pl-[82px] pr-[62px] md:pl-[240px] md:pr-[180px] lg:pl-[350px] lg:pr-[320px]  xl:pl-[480px] xl:pr-[440px]
              overflow-y-auto h-[38vh] leading-[40px]'
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {rawWordsDictList.map((item) => (
                <span key={item.id}
                  contentEditable
                  suppressContentEditableWarning={true}
                  onBlur={(e) => handleEdit(item.id, e.target.textContent)}
                  onKeyPress={preventInvalidInput}
                  style={{
                    outline: 'none',
                    display: 'inline-block',
                    backgroundColor: 'transparent',
                    color: wordsToLookAtList.includes(item.word.toLowerCase()) ? '#ff0000' : '#ffffff'
                  }}
                  onClick={() => handleWordClick(item.id)}
                >
                  {item.word + ' '}
                </span>
              ))}
            </div>

            <button id="done-button" className="relative px-6 py-2 bg-gray-900 w-[200px] rounded-3xl hover:opacity-50 flex-none"
              onClick={handleDoneButtonClick}
            >
                <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
                <p className="text-[#A343FF] font-bold relative">Done</p>
              </button>
        </div>
        )}

      </div>
  

    </div>
  )
};

