import AWS from 'aws-sdk';
import awsConfig from '../../Configs/awsConfig';
import { getAuth, signOut } from "firebase/auth";
import { GlobalContext } from '../../UtilityComponents/GlobalContextProvider';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SOAP_NOTE_STATUS_ENUM from '../../Enums/SOAP_NOTE_STATUS_ENUM';
import NOTES_LOADING_STATE_ENUM from '../../Enums/NOTES_LOADING_STATE_ENUM';
import { toast } from 'sonner'
import { fetchNotesFromIndexedDB } from '../../UtilityFunctions/utils';





// #region AWS setup
AWS.config.update({
  region: awsConfig.s3.region,
  accessKeyId: awsConfig.s3.accessKeyId,
  secretAccessKey: awsConfig.s3.secretAccessKey,
});
var ddb = new AWS.DynamoDB({ apiVersion: "2012-08-10" });
// #endregion





const useNotesPageVM = ()=>{

  // #region Context(s)
  const { user } = useContext(GlobalContext);
  // #endregion


  // #region Properties
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [notes, setNotes] = useState([]);
  const [loadingState, setLoadingState] = useState(NOTES_LOADING_STATE_ENUM.LOADING);
  // #endregion
  

  // #region Methods
  const fetchNotesFromDynamoDB = async (user) => {
    const params = {
      TableName: "SOAP_NOTES_TABLE",
      IndexName: "USER_ID-SOAP_NOTE_STATUS-index",
      KeyConditionExpression: "USER_ID = :userId AND SOAP_NOTE_STATUS = :soapNoteStatus",
      ExpressionAttributeValues: {
        ':userId': { "S": user.uid },
        ':soapNoteStatus': { "S": SOAP_NOTE_STATUS_ENUM.SOAP_NOTE_COMPLETED }
      },
      ProjectionExpression: "SOAP_NOTE_ID, DDB_TIMESTAMP, PATIENT_NAME, SOAP_NOTE_STATUS",
      ScanIndexForward: false,
    };
  
    const result = await ddb.query(params).promise();
  
    return result.Items.map(item => ({
      soapNoteId: item.SOAP_NOTE_ID.S,
      timestamp: item.DDB_TIMESTAMP.S,
      patientName: item.PATIENT_NAME.S,
      soapNoteStatus: item.SOAP_NOTE_STATUS.S,
      source: 'dynamoDb'
    }));  
  };
  
  const fetchNotes = async () => {
    try {
      const ddbNotes = await fetchNotesFromDynamoDB(user);
      // const indexedDBNotes = await fetchNotesFromIndexedDB();
  
      // const allNotes = [...dynamoNotes, ...indexedDBNotes];
  
      const chronologicallySortedNotes = ddbNotes.sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
  
      setNotes(chronologicallySortedNotes);
  
      if (ddbNotes.length > 0) {
        setLoadingState(NOTES_LOADING_STATE_ENUM.FINISHED_SUCCESSFULLY);
      } else {
        setLoadingState(NOTES_LOADING_STATE_ENUM.LOADED_WITHOUT_NOTES);
      }
  
    } catch (error) {
      console.log('error', error)
      setLoadingState(NOTES_LOADING_STATE_ENUM.ERROR_STATE);
    }
  };  
  // #endregion


  // #region Button handlers
  const handleSoapNoteRowClick = (soapNoteId, source) => {
    if (source === 'dynamoDb') {
      navigate('/soap-note', { state: { soapNoteId } });
    }
  };
  // #endregion


  // #region useEffect
  useEffect(() => {
    const fetchDataNeededForPage = async()=>{
      if(!navigator.onLine){
        setLoadingState(NOTES_LOADING_STATE_ENUM.NO_INTERNET)
        toast.error('Please check your internet connection')
        return;
      }
      if (!user) {
        navigate('/sign-in')
        return;
      }

      //already fetched notes
      if (notes.length > 0) {
        return;
      }
      await fetchNotes();  
    }
    fetchDataNeededForPage();
  }, []);
  // #endregion
  

  // #region return
  return {
    
    error,

    handleSoapNoteRowClick,

    loadingState,

    notes,
    
  };
  // #endregion

};



export default useNotesPageVM;