import useSignInPageVM from './useSignInPageVM';

import AUTH_STATE_ENUM from './AUTH_STATE_ENUM';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import glassyBgLoggedIn from '../../Images/glassyBgLoggedIn.jpg';



export default function SignInPage() {

  const viewModel = useSignInPageVM();
  
  return (
    <div id="bg-logged-out" className="relative bg-cover bg-center h-screen flex justify-center">
      {/* Background Image */}
      <img src={glassyBgLoggedIn} alt="heroBgImg" className="absolute inset-0 w-full h-full object-cover z-0" />

      {/* Foreground Content */}
      <div className="relative flex flex-col h-screen justify-start items-center z-20">

        <div id='magic-medic-ai-title'>
          <button className="font-bold hover:opacity-70 mb-6 mt-20 text-3xl text-white"
            onClick={viewModel.handleMagicMedicAIButtonClick}
          >
            Magic Medic AI
          </button>
        </div>

        { viewModel.currentUIState === AUTH_STATE_ENUM.FORGOT_PASSWORD && (
          <div id='ForgotPasswordCard' className='btn-shadow flex flex-col mt-12 px-12 py-8 rounded-xl space-y-6' style={{backgroundColor: 'rgba(221, 248, 252, 0.3)'}} >
          <div id='forgot-password-title-div' className="font-semibold text-2xl text-center text-white">
            Forgot password
          </div>
          <div id='email-textfield-and-error-message-div' className="flex flex-col items-start space-y-2">
            <div className="font-semibold text-white">Email</div>
            <input 
              className="border-2 border-gray-300 rounded p-2"
              onChange={(e) => viewModel.setEmail(e.target.value)} 
              style={{height: "3rem", width: '22rem' }} 
              type="text" 
              value={viewModel.email}
            />
            {viewModel.emailErrorTextIsPresented && (
              <div className="text-red-700 font-semibold">Please enter a valid email</div>
            )}
          </div>
          <button id="sign-in-button" className="relative mt-12 px-8 py-4 bg-gray-900 rounded-3xl hover:opacity-50"
              onClick={viewModel.handleForgotPasswordButtonClick}
            >
              <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
              <p className="text-[#A343FF] relative">Continue</p>
            </button>
          <button id='go-back-to-sign-in-button' className="font-semibold text-white hover:opacity-50" onClick={() => viewModel.setCurrentUIState(AUTH_STATE_ENUM.SIGN_IN)}>
            go back to sign in
          </button>
          </div>
        )}

        { viewModel.currentUIState === AUTH_STATE_ENUM.RESET_PASSWORD && (
          <div id='ResetPasswordCard' className='btn-shadow flex flex-col mt-12 px-12 py-8 rounded-xl space-y-4 text-white' style={{backgroundColor: 'rgba(221, 248, 252, 0.3)'}} >
            <div className="font-semibold mb-6 text-2xl text-center">Check your email</div>
            <div className="mb-6 flex items-center font-medium" style={{ width: '23rem' }}>
              We've sent you an email containing instructions for resetting your password. If you haven't received an email in 5 minutes, check your spam or try a different email.
            </div>
            <button id='go-back-to-sign-in-button' className="font-semibold text-white hover:opacity-50" onClick={() => viewModel.setCurrentUIState(AUTH_STATE_ENUM.SIGN_IN)}>
              go back to sign in
            </button>
          </div>        
        )}

        { viewModel.currentUIState === AUTH_STATE_ENUM.SIGN_IN && (
          <div id='SignInCard' className='btn-shadow flex flex-col mt-12 px-12 py-8 rounded-xl' style={{backgroundColor: 'rgba(221, 248, 252, 0.3)'}}>
            <div id='sign-in-to-your-account-title' className="font-semibold mb-6 text-2xl text-center text-white">Sign In</div>
            <div id='email-div' className="flex flex-col items-start">
              <div className="font-semibold mb-2 text-white">Email</div>
              <input id='email-textfield' className="border-2 border-gray-300 rounded p-2 mb-6" onChange={(e) => viewModel.setEmail(e.target.value)} style={{height: "3rem", width: '22rem' }} type="text" value={viewModel.email} />
            </div>
            <div className="items-start">
              <div className="flex flex-row justify-between mb-2 text-white">
                <p className="font-semibold">Password</p>
                <button className="font-bold text-gray-800 hover:opacity-50" onClick={()=>viewModel.setCurrentUIState(AUTH_STATE_ENUM.FORGOT_PASSWORD)}>Forgot password?</button>
              </div>
              <div className="relative">
                <input id='password-textfield' className="border-2 border-gray-300 p-2 rounded w-full" onChange={(e) => viewModel.setPassword(e.target.value)} style={{ height: "3rem", width: "22rem" }} type={viewModel.showPassword ? "text" : "password"} value={viewModel.password} />
                <button onClick={viewModel.togglePasswordVisibility} className="absolute inset-y-0 right-0 pr-3 flex text-sm leading-5">
                  <FontAwesomeIcon className="text-gray-900" icon={viewModel.showPassword ? faEye : faEyeSlash} style={{ top: '150%', transform: 'translateY(120%)' }} />
                </button>
              </div>
            </div>
            {viewModel.authErrorTextIsPresented && (
              <div className="flex font-semibold mt-2 text-red-700 text-lg text-start">Incorrect email or password</div>
            )}
            <button id="sign-in-button" className="relative mt-12 px-8 py-4 bg-gray-900 rounded-3xl hover:opacity-50"
              onClick={viewModel.handleSignInButtonClick}
            >
              <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
              <p className="text-[#A343FF] relative">Sign in</p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
  
}