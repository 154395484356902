import AWS from 'aws-sdk';
import awsConfig from '../../Configs/awsConfig.js'; 
import axios from 'axios';
import { GlobalContext } from '../../UtilityComponents/GlobalContextProvider.js';
import SOAP_NOTE_STATUS_ENUM from '../../Enums/SOAP_NOTE_STATUS_ENUM.js';
import TRANSCRIPTION_PAGE_ENUM from '../../Enums/TRANSCRIPTION_PAGE_ENUM.js';
import { TranscriptionProcessContext } from "../Transcription_Process_MultiPage/TranscriptionProcessContext.jsx";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';



// #region AWS setup
// I'm initializing these AWS services outside of the hook
// to avoid re-creating it on every render.
AWS.config.update({
  region: awsConfig.s3.region,
  accessKeyId: awsConfig.s3.accessKeyId,
  secretAccessKey: awsConfig.s3.secretAccessKey,
});

var ddb = new AWS.DynamoDB({ apiVersion: "2012-08-10" });
// #endregion


const useCorrectionPageVM = () => {

  // #region Contexts
  const {
    setCompletedSoapNoteDict,
    audioBlobUrl,
    audioBlobRef,
    setCurrentTranscriptionPage,
    soapNoteId,
  } = useContext(TranscriptionProcessContext);

  const {
    user
  } = useContext(GlobalContext)
  // #endregion

  
  // #region Properties
  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate();
  const [rawWordsDictList, setRawWordsDictList] = useState([]);
  const [wordsToLookAtList, setWordsToLookAtList] = useState([]);
  const [inErrorState, setInErrorState] = useState(false);
  // #endregion

  
  // #region methods
  const fetchCompletedSoapNoteAndStoreInTheContext = async () => {
    // #region #1. Define the params
    const params = {
      TableName: "SOAP_NOTES_TABLE",
      Key: {  "SOAP_NOTE_ID": { S: soapNoteId } },
      ProjectionExpression: "SOAP_NOTE_DICT"
    }
    let Item
    // #endregion

    // #region #2. Fetch the completed SOAP note
    try{
      const retrievedData = await ddb.getItem(params).promise();
      Item = retrievedData.Item;
      // console.log(Item)
    } catch (error){
      //TODO: Handle this error
      
    }
    // #endregion
    
    // #region #3. Parse the data
    const completedSoapNoteDict = parseCompletedSoapNoteDict(Item);
    // #endregion

    // #region #4. Store the data in the context
    setCompletedSoapNoteDict(completedSoapNoteDict);
    // #endregion
  }


  const fetchSoapNoteDataItem = async () => {
    const params = {
      TableName: "SOAP_NOTES_TABLE",
      Key: { "SOAP_NOTE_ID": { S: soapNoteId } },
      ProjectionExpression: "RAW_WORDS_DICT_LIST, WORDS_TO_LOOK_AT_LIST"
    }

    // try{
    const retrievedData = await ddb.getItem(params).promise();
    return retrievedData.Item

  }


  const parseCompletedSoapNoteDict = (Item) => {
    const CHIEF_COMPLAINT = Item.SOAP_NOTE_DICT.M.CHIEF_COMPLAINT.S
    const HISTORY_OF_PRESENT_ILLNESS = Item.SOAP_NOTE_DICT.M.HISTORY_OF_PRESENT_ILLNESS.S
    const PAST_MEDICAL_HISTORY = Item.SOAP_NOTE_DICT.M.PAST_MEDICAL_HISTORY.S
    const PAST_SURGICAL_HISTORY = Item.SOAP_NOTE_DICT.M.PAST_SURGICAL_HISTORY.S
    const FAMILY_HISTORY = Item.SOAP_NOTE_DICT.M.FAMILY_HISTORY.S
    const SOCIAL_HISTORY = Item.SOAP_NOTE_DICT.M.SOCIAL_HISTORY.S
    const REVIEW_OF_SYSTEMS = Item.SOAP_NOTE_DICT.M.REVIEW_OF_SYSTEMS.S
    const ALLERGIES = Item.SOAP_NOTE_DICT.M.ALLERGIES.S
    const VITALS = Item.SOAP_NOTE_DICT.M.VITALS.S
    const PLAN = Item.SOAP_NOTE_DICT.M.PLAN.S
    const completedSoapNoteDict = {
      "CHIEF_COMPLAINT": CHIEF_COMPLAINT,
      "HISTORY_OF_PRESENT_ILLNESS": HISTORY_OF_PRESENT_ILLNESS,
      "PAST_MEDICAL_HISTORY": PAST_MEDICAL_HISTORY,
      "PAST_SURGICAL_HISTORY": PAST_SURGICAL_HISTORY,
      "FAMILY_HISTORY": FAMILY_HISTORY,
      "SOCIAL_HISTORY": SOCIAL_HISTORY,
      "REVIEW_OF_SYSTEMS": REVIEW_OF_SYSTEMS,
      "ALLERGIES": ALLERGIES,
      "VITALS": VITALS,
      "PLAN": PLAN
    }
    return completedSoapNoteDict
  }

  const parseRawWordsDictList = (Item) => {
    return Item.RAW_WORDS_DICT_LIST.L
      .map(transcriptDict => ({
        word: transcriptDict.M.word.S,
        start: parseFloat(transcriptDict.M.start.S),
        end: parseFloat(transcriptDict.M.end.S),
        id: parseInt(transcriptDict.M.id.S)
      }))
  }

  const parseWordsToLookAtList = (Item) => {
    return Item.WORDS_TO_LOOK_AT_LIST.L
      .map(wordsToLookAtDict => wordsToLookAtDict.S)
  }

  const pollToCheckIfSoapNoteIsReady = (pollParams) => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        try{
          const { Item } = await ddb.getItem(pollParams).promise();
          if (Item && Item.SOAP_NOTE_STATUS.S === SOAP_NOTE_STATUS_ENUM.SOAP_NOTE_COMPLETED){
            clearInterval(interval);
            resolve();
          }
        } catch (error){
          //TODO: Handle this error
          clearInterval(interval);
          reject(error);
        }
      }, 5000);
    });
  }
  // #endregion

  
  // #region event handlers
  const handleDoneButtonClick = async () => {
    // #region 1. Confirm that the user is done
    const userConfirmedDoneAction = window.confirm("Click ok to confirm that you are done");
    if (!userConfirmedDoneAction) { return; }
    // #endregion

    setIsLoading(true);

    // #region 2. Set up for variables needed for uploading to the backend
    const reviewedTranscript = rawWordsDictList.map(item => item.word).join(" ");
    // #endregion

    // #region 3. Set up the AWS params
    const reviewedTranscriptParams = {
      TableName: "SOAP_NOTES_TABLE",
      Key: { 
        "SOAP_NOTE_ID": { S: soapNoteId }
      },
      UpdateExpression: `
        set REVIEWED_TRANSCRIPT_STRING = :reviewedTranscript
      `,
      ExpressionAttributeValues: {
        ":reviewedTranscript": { S: reviewedTranscript }
      }
    }

    const pollParams = {
      TableName: "SOAP_NOTES_TABLE",
      Key: { 
        "SOAP_NOTE_ID": { S: soapNoteId }
      },
      ProjectionExpression: "SOAP_NOTE_STATUS"
    }
    // #endregion

    // #region 4. Upload the reviewed transcript to ddb
    try{
      // console.log("reviewedTranscript is\n",reviewedTranscript);
      await ddb.updateItem(reviewedTranscriptParams).promise();
    } catch (error){
      //TODO: Handle this error
      return
    }
    // #endregion

    // #region 5. Trigger the lambda to create the soap note with api call
    const userEmail = user.email
    try{
      await axios.post(
        `https://sfink834l8.execute-api.us-east-2.amazonaws.com/Create_Soap_Note?soap_note_id=${soapNoteId}&email=${userEmail}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          }
        }
      )
      // console.log("response is", response);
    } catch (error){
      //TODO: Handle this error
      return
    }
    // #endregion

    // #region 6. Poll to check if the SOAP note is ready
    try{ 
      await pollToCheckIfSoapNoteIsReady(pollParams);
    } catch (error){
      //TODO: Handle this error
      return
    }
    // #endregion

    // #region 7. Handle the soap note being ready
    await handleCompletedSoapNoteBeingReady();
    // #endregion
  }
  
  const handleEdit = (id, newWord) => {
      // Remove spaces and newlines
  const sanitizedWord = newWord.replace(/[\s\n]/g, '');

    // First, find the current word to compare
    const currentItem = rawWordsDictList.find(item => item.id === id);
  
    // Check if the new word is different from the current word
    if (currentItem && currentItem.word !== sanitizedWord) {
      const updatedRawWordsDictList = rawWordsDictList.map(item => 
        item.id === id ? { ...item, word: sanitizedWord } : item
      );
      setRawWordsDictList(updatedRawWordsDictList);
    }
  };

  const handleMinus5SecondsButtonClick = () => {
    if (audioBlobRef.current) {
      audioBlobRef.current.currentTime = Math.max(
        0, audioBlobRef.current.currentTime - 5
      );
    }
  }

  const handlePlus5SecondsButtonClick = () => {
    if (audioBlobRef.current){
      audioBlobRef.current.currentTime = Math.min(
        audioBlobRef.current.duration, audioBlobRef.current.currentTime + 5
      );
    }
  }

  const handleWordClick = (id) => {
    const item = rawWordsDictList.find(word => word.id === id);
    if (audioBlobRef.current && item) {
      audioBlobRef.current.currentTime = Math.max(0, item.start - 2);
    }
  };

  const handleCompletedSoapNoteBeingReady = async()=>{
    // #region 1. Fetch the completed SOAP note
    //TODO: Probably remove this. I don't end up using the soap note fetched here.
    // I do a fetch in the soap note page anyway
    await fetchCompletedSoapNoteAndStoreInTheContext();
    // #endregion

    // #region 2. Redirect the user to the SOAP note page
    navigate('/soap-note', { state: { soapNoteId } });
    setCurrentTranscriptionPage(TRANSCRIPTION_PAGE_ENUM.SESSION_INFO) ;
    // #endregion
  };

  const preventInvalidInput = (e) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
    }
  };
  // #endregion

  
  // #region useEffect
  useEffect(() => {
    if (rawWordsDictList.length > 0 || wordsToLookAtList.length > 0){
      // console.log("Data already loaded")
      return;
    }

    const loadDataForVM = async () => {
        try{
        const soapNoteDataItem = await fetchSoapNoteDataItem();
        if (!soapNoteDataItem){ return; }
        const rawWordsDictList = parseRawWordsDictList(soapNoteDataItem);
        const wordsToLookAtList = parseWordsToLookAtList(soapNoteDataItem);
        setRawWordsDictList(rawWordsDictList);
        setWordsToLookAtList(wordsToLookAtList);
        // await loadDummyAudioBlob();
      } catch (error) {
        //TODO: Handle this error
      }
    }

    loadDataForVM();
  }, []);
  // #endregion

  
  // #region Return
  return{

    audioBlobUrl,

    audioBlobRef,

    handleDoneButtonClick,

    handleEdit,

    handleMinus5SecondsButtonClick,

    handlePlus5SecondsButtonClick,

    handleWordClick,

    isLoading,

    preventInvalidInput,

    rawWordsDictList, 

    setRawWordsDictList,

    wordsToLookAtList

  };
  // #endregion

}

export default useCorrectionPageVM;