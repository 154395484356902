import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function SuccessfulPaymentPage() {
  const navigate = useNavigate();
  const handleContinueToSessionInfoPageButtonClick = ()=>{
    navigate('/transcription-process');
  }
  return (
    <div id="bg-logged-in" className='bg-loggedin bg-cover bg-center h-[100vh] flex justify-center items-center'>
      <div className='flex flex-col items-center space-y-4'>
        <h1 className='text-white font-medium text-lg'
        >Payment Successful</h1>
        <button id="continue-to-session-info-button" 
          className="relative py-3 bg-gray-900 w-[300px] rounded-3xl hover:opacity-50 flex-none"
          onClick={handleContinueToSessionInfoPageButtonClick}
        >
          <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
          <p className="text-[#A343FF] font-bold relative">Continue</p>
        </button>

      </div>
    </div>
  );
}