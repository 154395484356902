import { auth } from '../../Configs/firebaseConfig';
import { confirmPasswordReset } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";


const PasswordResetPageVM = ()=>{

  // #region Methods
  const handlePasswordResetSubmission = async ()=>{
    setPresentPasswordMismatchError(false);
    setPresentPasswordRequirements(false);
  
    // Check if passwords match
    if (newPassword !== confirmNewPasswordText) {
      setPresentPasswordMismatchError(true);
      return;
    }
  
    // Validate password complexity
    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasNumbers = /\d/.test(newPassword);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
  
    if (newPassword.length < 8 || !hasUpperCase || !hasLowerCase || !hasNumbers || !hasSpecialChars) {
      setPresentPasswordRequirements(true);
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const oobCode = queryParams.get('oobCode'); // Get the oobCode from the URL
  
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setPasswordResetSuccessful(true);
    } catch (error) {
      //TODO: Handle this error
    }
  };

  const handleRedirectToSigninPage = ()=>{
    // console.log('redirecting to sign in page');
    navigate('/sign-in');
  };

  const togglePasswordVisibility = ()=>{
    setShowPassword(!showPassword);
  };
  // #endregion


  // #region Properties
  const [confirmNewPasswordText, setConfirmNewPasswordText] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
  const [presentPasswordMismatchError, setPresentPasswordMismatchError] = useState(false);
  const [presentPasswordRequirements, setPresentPasswordRequirements] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // #endregion


  return {
    confirmNewPasswordText, setConfirmNewPasswordText,
    handlePasswordResetSubmission,
    handleRedirectToSigninPage,
    newPassword, setNewPassword,
    passwordResetSuccessful, setPasswordResetSuccessful,
    presentPasswordMismatchError, setPresentPasswordMismatchError,
    presentPasswordRequirements, setPresentPasswordRequirements,
    showPassword,
    togglePasswordVisibility
  };

};

export default PasswordResetPageVM;