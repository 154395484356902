// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "jetpackcomposemedprojapp.firebaseapp.com",
  projectId: "jetpackcomposemedprojapp",
  storageBucket: "jetpackcomposemedprojapp.appspot.com",
  messagingSenderId: "160969627096",
  appId: "1:160969627096:web:0a30a3d7049d6b25060ead"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);

