import LandingPageVM from './LandingPageVM';
import React from 'react';





function LandingPage() {

  const {
    navigateToJoinWaitlist,
    navigateToLogin
  } = LandingPageVM();


  return (
    <div className="background-with-overlay flex flex-col">
      <div className='relative z-10'>
        <div id='navbar' className="flex justify-between items-center px-16 py-10 text-white">
          <span id='magic-medic-ai-title' className="mm-text-shadow text-lg font-semibold mx-2.5">
            Magic Medic AI
          </span>
          <div id='right-side-navbar-buttons' className='space-x-8'>
            <button className='font-bold'>
            Pricing
            </button>
            <button className="bg-black btn-shadow rounded-full px-6 py-3 font-medium hover:opacity-50 mx-2.5 text-base" onClick={navigateToLogin}  >
              Sign in
            </button>
          </div>
        </div>
        <div className="flex flex-col h-[calc(80vh-80px)] items-start justify-start mt-20 px-20 text-white">
          <div className="sm:text-3xl md:text-4xl lg:text-6xl text-3xl font-semibold space-y-8 text-left leading-snug">
            <h1>
              Simplify Your Workflow
            </h1>
            <p className="sm:text-xl md:text-2xl lg:text-3xl text-xl font-medium text-left">
              Automatated notes for patient visits
            </p>
            <button
              className="bg-black btn-shadow sm:px-[116px] sm:py-4 sm:text-base  md:px-[145px] md:py-[18px] md:text-base  lg:px-[194px] lg:py-[20px] lg:text-base  px-[134px] py-[18px] text-xs text-white font-medium hover:bg-opacity-60 hover:opacity-50 rounded-full shadow-lg"
              onClick={navigateToJoinWaitlist}
            >
              Join Waitlist
            </button>
          </div>
        </div>
      </div>

      <div id='footer-section' className="sm:text-[8px] md:text-[9px] lg:text-[10px] text-[6px] bg-black grid grid-cols-4 gap-x-20 px-16 py-8 -translate-y-20">
        
        <div id='magic-medic-ai-footer-col' className="font-medium px-1 space-y-2 text-left text-white">
          <div className="font-semibold">Magic Medic AI</div>
          <div className="text-opacity-50 text-white">
            Copyright © 2024<br/>
            All rights reserved<br/>
            support@magicmedicai.com<br/>
          </div>
        </div>

        <div id='security-footer-col' className="font-medium space-y-2 text-left text-white">

          <div>Security</div>
          <div className="text-opacity-50 text-white">

            <div className='flex flex-row space-x-1'>
              <p>
                • 
              </p>
              <p>
                HIPAA compliant
              </p>
            </div>
            <div className='flex flex-row space-x-1'>
              <p>
                •
              </p>
              <p>
                You can delete your data any time you wish
              </p>
            </div>

          </div>
        </div>

        <div id='houston-office-footer-col' className="font-medium space-y-2 text-left text-white">
          <div>Houston Office</div>
          <div className="text-opacity-50 text-white">
            4201 Main St,<br/> 
            Houston, TX 77002        
          </div>
        </div>

        <div id='delaware-office-footer-col' className="font-medium space-y-2 text-left text-white">
          <div>Delaware Office</div>
          <div className="text-opacity-50 text-white">
            251 Little Falls Drive,<br /> 
            Wilmington, Delaware 19808
          </div>
        </div>

      </div>

    </div>
  );
}

export default LandingPage;
