import useCreateAccountPageVM from './useCreateAccountPageVM';

import CREATE_ACCOUNT_ENUM from '../../Enums/CREATE_ACCOUNT_ENUM';
import { faEye, faEyeSlash, faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import heroBgImg from '../../Images/heroBgImg.jpg';
import React, { memo } from 'react';

import filledCircle from '../../Images/filledCircle.png';
import strokedCircle from '../../Images/strokedCircle.png';
import glassyBgLoggedIn from '../../Images/glassyBgLoggedIn.jpg';




export default function CreateAccountPage(){

  const { 
    
    confirmedCode,

    currentStep,

    errors, 

    handleConfirmedCodeInputFieldEvent,

    handleContinueButtonClickOnConfirmCodeStep,

    handleContinueButtonClickOnNamesAndEmailStep,

    handleContinueButtonClickOnPasswordsStep,

    handleContinueButtonClickOnPhoneNumberStep,

    handleLeftArrowClick,

    handlePhoneNumberChange,

    handleResendButtonClick,

    passwordVisible,

    phoneNumber,

    setConfirmedPassword, confirmedPassword,

    setEmail, email,

    setFirstName, firstName,
    
    setLastName, lastName,
    
    setPassword, password,
    
    togglePasswordVisibility,

  } = useCreateAccountPageVM();


  return (
    <div id="bg-logged-out" className="relative bg-cover bg-center h-screen flex justify-center">
      {/* Background Image */}
      <img src={glassyBgLoggedIn} alt="heroBgImg" className="absolute inset-0 w-full h-full object-cover z-0" />

      {/* Foreground Content */}
      <div id='foreground-content' className="relative flex flex-col h-screen justify-start items-center z-20">

        <div id='magic-medic-ai-title' className="font-bold hover:opacity-70 mb-6 mt-20 text-3xl text-white">
          Getting Started
        </div>

        {/* Creating an account Step 1 */
        currentStep === CREATE_ACCOUNT_ENUM.NAMES_AND_EMAIL && (
          <div id='create-account-step-1' className='btn-shadow flex flex-col mt-2 px-12 py-8 rounded-xl space-y-6' style={{backgroundColor: 'rgba(221, 248, 252, 0.3)'}}>
            <div id='create-account-title' className="font-semibold text-2xl text-left text-white">Creating an account</div>
            
            <div id='firstname-section' className="flex flex-col items-start">
              <div className="font-semibold mb-2 text-white">First name</div>
              <input id='firstname-textfield' className="border-2 border-gray-300 rounded p-2" onChange={(e) => setFirstName(e.target.value)} style={{height: "3rem", width: '22rem' }} type="text" value={firstName} />
              {errors.firstName && (
                <div className="flex font-semibold mt-2 text-red-700 text-lg text-start">
                 {errors.firstName}
                </div>
              )}
            </div>

            <div id='lastname-section' className="flex flex-col items-start">
              <div className="font-semibold mb-2 text-white">Last name</div>
              <input id='lastname-textfield' className="border-2 border-gray-300 rounded p-2" onChange={(e) => setLastName(e.target.value)} style={{height: "3rem", width: '22rem' }} type="text" value={lastName} />
              {errors.lastName && (
                <div className="flex font-semibold mt-2 text-red-700 text-lg text-start">
                  {errors.lastName}
                </div>
              )}
            </div>

            <div id='email-section' className="flex flex-col items-start">
              <div className="font-semibold mb-2 text-white">Email</div>
              <input id='email-textfield' className="border-2 border-gray-300 rounded p-2" onChange={(e) => setEmail(e.target.value)} style={{height: "3rem", width: '22rem' }} type="text" value={email} />
              {errors.email && (
                <div className="flex font-semibold mt-2 text-red-700 text-lg text-start">
                  {errors.email}
                </div>
              )}
            </div>

            <button id="continue-button" className="relative px-8 py-4 bg-gray-900 rounded-3xl hover:opacity-50"
              onClick={handleContinueButtonClickOnNamesAndEmailStep}
            >
              <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
              <p className="text-[#A343FF] relative">Continue</p>
            </button>
            <div id='progress-indicator' className='flex flex-row space-x-4 items-center justify-center'>
                <img src={filledCircle } alt="filledCircle" className="size-3" />
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
              </div>

          </div> 
        )}

        {/* Creating an account Step 2 */
        currentStep === CREATE_ACCOUNT_ENUM.PASSWORDS && (
          <div id='step-2-row' className='flex flex-row items-center space-x-8'>

            <button id='left-arrow' className='hover:opacity-50' onClick={handleLeftArrowClick}>
              <FontAwesomeIcon className=" size-10" icon={faCircleArrowLeft} style={{ color: 'rgba(221, 248, 252, 0.3)', fontSize: '1.25rem' }}/>  
            </button>

            <div id='create-account-step-2' className='btn-shadow flex flex-col mt-12 px-12 py-8 rounded-xl space-y-6' style={{backgroundColor: 'rgba(221, 248, 252, 0.3)'}}>
              
              <div id='creating-account-title' className="font-semibold text-2xl text-left text-white">
                Creating an account
              </div>
              
              <div id='password' className="items-start">
                <p id='password-label' className="flex flex-row justify-between mb-2 text-white font-semibold">
                  Password
                </p>
                <div id='pw-field-and-eye-row' className="relative">
                    <input id='password-textfield' className="border-2 border-gray-300 p-2 rounded w-full" onChange={(e) => setPassword(e.target.value)} style={{ height: "3rem", width: "22rem" }} type={passwordVisible ? "text" : "password"} value={password} />
                    <button onClick={togglePasswordVisibility} className="absolute inset-y-0 right-0 pr-3 flex text-sm leading-5">
                      <FontAwesomeIcon className="text-gray-900" icon={passwordVisible ? faEye : faEyeSlash} style={{ top: '150%', transform: 'translateY(120%)' }} />
                    </button>
                </div>
                {errors.password && (
                  <div className="flex font-semibold mt-2 text-red-700 text-lg text-start">
                    {errors.password}
                  </div>
                )}
              </div>

              <div id='confirmed-password' className="items-start">
                <div className="flex flex-row justify-between mb-2 text-white">
                  <p className="font-semibold">Confirm password</p>
                </div>
                <div className="relative">
                  <input id='confirmed-password-textfield' className="border-2 border-gray-300 p-2 rounded w-full" onChange={(e) => setConfirmedPassword(e.target.value)} style={{ height: "3rem", width: "22rem" }} type={passwordVisible ? "text" : "password"} value={confirmedPassword} />
                </div>
                {errors.confirmedPassword && (
                  <div className="flex font-semibold mt-2 text-red-700 text-lg text-start">
                    {errors.confirmedPassword}
                  </div>
                )}
              </div>
              
              <button id="continue-button" className="relative px-8 py-4 bg-gray-900 rounded-3xl hover:opacity-50"
                onClick={handleContinueButtonClickOnPasswordsStep}
              >
                <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"/>
                <p className="text-[#A343FF] relative">Continue</p>
              </button>

              <div id='progress-indicator' className='flex flex-row space-x-4 items-center justify-center'>
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
                <img src={filledCircle } alt="filledCircle" className="size-3" />
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
              </div>

            </div> 

            <button id='hidden-right-arrow' className='hover:opacity-50' >
              <FontAwesomeIcon className=" text-white size-10 opacity-0" icon={faCircleArrowRight}  />  
            </button>

          </div>
        )}

        {/* Creating an account Step 3 */
        currentStep === CREATE_ACCOUNT_ENUM.PHONE_NUMBER && (
          <div id='step-3-row' className='flex flex-row items-center space-x-8'>
            <button id='left-arrow' className='hover:opacity-50' onClick={handleLeftArrowClick}>
              <FontAwesomeIcon className="size-10" icon={faCircleArrowLeft} style={{ color: 'rgba(221, 248, 252, 0.3)', fontSize: '1.25rem' }} />
            </button>
      
            <form id='create-account-step-3' className='btn-shadow flex flex-col mt-12 px-12 py-8 rounded-xl space-y-6' style={{ backgroundColor: 'rgba(221, 248, 252, 0.3)' }}>
              <div id='creating-account-title' className="font-semibold text-2xl text-left text-white">
                Creating an account
              </div>
              <div id='phone-number-section' className="flex flex-col items-start">
                <label htmlFor='phone-number-input-field' className="font-semibold mb-2 text-white">Phone number</label>
                <input
                  id='phone-number-input-field'
                  className="border-2 border-gray-300 rounded p-2"
                  onChange={handlePhoneNumberChange}
                  style={{ height: "3rem", width: '22rem' }}
                  type="text"
                  value={phoneNumber}
                  maxLength="12" // Adjust based on your phone number format
                />
                {errors.phoneNumber && (
                  <div className="flex font-semibold mt-2 text-red-700 text-lg text-start">
                    {errors.phoneNumber}
                  </div>
                )}
              </div>
              <button id="continue-button" className="relative px-8 py-4 bg-gray-900 rounded-3xl hover:opacity-50" type="button" onClick={handleContinueButtonClickOnPhoneNumberStep}>
                <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
                <p className="text-[#A343FF] relative">Continue</p>
              </button>
              <div id='progress-indicator' className='flex flex-row space-x-4 items-center justify-center'>
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
                <img src={filledCircle} alt="filledCircle" className="size-3" />
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
              </div>
            </form>
      
            <button id='hidden-right-arrow' className='hover:opacity-50'>
              <FontAwesomeIcon className="size-10 opacity-0" icon={faCircleArrowRight} style={{ color: 'rgba(221, 248, 252, 0.3)', fontSize: '1.25rem' }} />
            </button>
          </div>  
        )}

        {/* Creating an account Step 4 */
        currentStep === CREATE_ACCOUNT_ENUM.CONFIRM_CODE && (
          <div className='flex flex-row items-center space-x-8'>

            <button id='left-arrow-circle' className='hover:opacity-50' onClick={handleLeftArrowClick}>
              <FontAwesomeIcon className=" size-10" icon={faCircleArrowLeft} style={{ color: 'rgba(221, 248, 252, 0.3)', fontSize: '1.25rem' }}/>  
            </button>

            <div id='create-account-step-4' className='btn-shadow flex flex-col mt-12 px-12 py-8 rounded-xl space-y-8' style={{backgroundColor: 'rgba(221, 248, 252, 0.3)'}}>

              <div id='create-account-title' className="font-semibold text-2xl text-left text-white">
                Creating an account
              </div>

              <div id='6digit-code-section' className="flex flex-col items-start">
                <div className="font-semibold mb-2 text-white">Code sent to your phone</div>
                <input 
                  id='confirm-code-input-field' 
                  className="border-2 border-gray-300 rounded p-2" 
                  onChange={handleConfirmedCodeInputFieldEvent} 
                  style={{height: "3rem", width: '22rem' }} 
                  type="text" 
                  value={confirmedCode} 
                  placeholder='Enter the 6 digit code'
                />
                {errors.confirmedCode && (
                  <div className="flex font-semibold mt-2 text-red-700 text-lg text-start">
                  {errors.confirmedCode}
                  </div>
                )}
              </div>

              <div id='continue-button-and-resend-col' className='w-full flex flex-col space-y-2'>
                <button id="continue-button" className="relative w-full px-8 py-4 bg-gray-900 rounded-3xl hover:opacity-50"
                  onClick={handleContinueButtonClickOnConfirmCodeStep}
                >
                  <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
                  <p className="text-[#A343FF] relative">Continue</p>
                </button>

                <div id='resend-row' className='flex flex-row justify-center'>
                  <p className='text-white text-opacity-50'>Didn't receive the code?</p>
                  <button id='resend-button' className='text-[#A343FF] hover:underline ml-2 font-semibold'
                    onClick={handleResendButtonClick}
                  >
                    Resend
                  </button>
                </div>

              </div>

              <div id='progress-indicator' className='flex flex-row space-x-4 items-center justify-center'>
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
                <img src={strokedCircle} alt="strokedCircle" className="size-3" />
                <img src={filledCircle } alt="filledCircle" className="size-3" />
              </div>
            </div> 

            <button id='hidden-right-arrow-circle' className='hover:opacity-50'>
              <FontAwesomeIcon className="size-10 opacity-0" icon={faCircleArrowRight} style={{ color: 'rgba(221, 248, 252, 0.3)', fontSize: '1.25rem' }}/>  
            </button>

          </div>
        )}

      </div>

    </div>
  );
}