import { auth } from '../../Configs/firebaseConfig';
import { confirmPasswordReset } from "firebase/auth";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useLocation } from "react-router-dom"; // if using react-router
import { useNavigate } from 'react-router-dom';

import PasswordResetPageVM from './PasswordResetPageVM';


function PasswordResetPage() {

  const {
    confirmNewPasswordText, setConfirmNewPasswordText,
    handlePasswordResetSubmission,
    handleRedirectToSigninPage,
    newPassword, setNewPassword,
    passwordResetSuccessful, 
    presentPasswordMismatchError,
    presentPasswordRequirements,
    showPassword,
    togglePasswordVisibility
  } = PasswordResetPageVM();

  return (
    <div className="bg-gray-50 flex flex-col h-screen justify-start items-center">
      <div className="flex flex-col items-center"> 
        <div className="font-bold mb-8 mt-20">
          Magic Medic AI 
        </div>
        <div className="bg-white p-8 border-2 border-gray-200 rounded shadow-lg">
          <div className="flex flex-col items-center"> 
            {passwordResetSuccessful ? (
              <>
                <div className="font-medium mb-8 text-1xl">
                  Password reset was successful
                </div>

                < button 
                  className="font-semibold hover:opacity-75 text-blue-500 text-sm"
                  onClick={handleRedirectToSigninPage}
                >
                  Click here to sign in with your new password
                </button>
              </>
            ):(
              <>
                <div className="font-semibold mb-8 text-2xl">
                    Password Reset
                </div>

                <div className="flex flex-col items-start">
                  <div className="font-medium mb-2 text-sm">
                    New Password
                  </div>
                  <div className="relative">
                    <input 
                      className="border-2 border-gray-300 p-1 rounded w-full"
                      onChange={(e) => setNewPassword(e.target.value)}
                      style={{ height: "3rem", width: "22rem" }}
                      type={showPassword ? "text" : "password"}
                      value={newPassword}
                      />
                    <button 
                      onClick={togglePasswordVisibility} 
                      className="absolute inset-y-0 right-0 pr-3 flex text-sm leading-5" >
                      <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className="text-gray-400" 
                      style={{ top: '150%', transform: 'translateY(120%)' }}
                      />
                    </button>
                  </div>
                </div>

                <div className="flex flex-col items-start">
                  <div className="font-medium mb-2 mt-8 text-sm">
                    Confirm New Password
                  </div>
                  <div className="relative">
                    <input 
                      className="border-2 border-gray-300 p-1 rounded w-full"
                      onChange={(e) => setConfirmNewPasswordText(e.target.value)}
                      style={{ height: "3rem", width: "22rem" }}
                      type={showPassword ? "text" : "password"}
                      value={confirmNewPasswordText}
                      />
                    <button 
                      onClick={togglePasswordVisibility} 
                      className="absolute inset-y-0 right-0 pr-3 flex text-sm leading-5" >
                      <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className="text-gray-400" 
                      style={{ top: '150%', transform: 'translateY(120%)' }}
                      />
                    </button>
                  </div>
                </div>

                {presentPasswordMismatchError && (
                  <div className="font-semibold mt-2 text-red-700 text-sm">
                    Passwords do not match
                  </div>
                )}

                {presentPasswordRequirements && (
                  <div className="font-semibold mt-2 text-red-700 text-sm">
                    Password must meet the following requirements:
                      <ul>
                        <li>• Minimum 8 characters length</li>
                        <li>• Includes uppercase and lowercase letters</li>
                        <li>• Contains numbers (0-9)</li>
                        <li>• Uses special characters (e.g., !, @, #, $)</li>
                      </ul>
                  </div>
                )}

                <button 
                  className="bg-black font-semibold mt-8 p-2 rounded shadow-md text-white" 
                  onClick={handlePasswordResetSubmission}
                  style={{height: "3rem",  width: '22rem' }}
                  >
                  Continue
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetPage;