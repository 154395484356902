import CUSTOMER_TYPE_ENUM from "../Enums/CUSTOMER_TYPE_ENUM.js";
import { openDB } from 'idb';


// utils.js
export const generateUUID = () => {
  let uuid = '';
  while (uuid.length < 16) {
    uuid += Math.floor(Math.random() * 10);
  }
  return uuid;
};

export const getIso8601Timestamp = () => {
  const date = new Date();
  return date.toISOString();  // Outputs in ISO 8601 format, e.g., "2024-04-23T12:34:56.789Z"
};

export const generateFormattedDateString = () => {
  const date = new Date(); // Gets the current date and time
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours.toString().padStart(2, '0');

  return `${month}${day}at${hours}${minutes}${ampm}`;
};

export const generateObjectKey = (userID, soapNoteId, fileExtension) => {
  return `${userID}/${soapNoteId}${fileExtension}`;
}

export const getFileExtensionForMimeType = (mimeType) => {
  const extensionMap = {
    'audio/webm': '.webm',
    'audio/mp4': '.mp4',
    'audio/webm;codecs=opus': '.webm',  // Assume .webm for simplicity
    'audio/ogg': '.ogg'
  };

  // Return the corresponding file extension, or an empty string if the MIME type is not found
  return extensionMap[mimeType] || '';
}

export const validateEmail = (email)=>{
  const re = /\S+@\S+\.\S+/;
  return email && re.test(email);
}

export const getCustomerType = (notesCreatedCount, isSubscribed) => {
  if (notesCreatedCount < 10 && !isSubscribed) {
    return CUSTOMER_TYPE_ENUM.LESS_THAN_10_NC_AND_NOT_SUBSCRIBED;
  } else if (notesCreatedCount < 10 && isSubscribed) {
    return CUSTOMER_TYPE_ENUM.LESS_THAN_10_NC_AND_IS_SUBSCRIBED;
  } else if (notesCreatedCount >= 10 && !isSubscribed) {
    return CUSTOMER_TYPE_ENUM.GREATER_THAN_10_NC_AND_NOT_SUBSCRIBED;
  } else if (notesCreatedCount >= 10 && isSubscribed) {
    return CUSTOMER_TYPE_ENUM.GREATER_THAN_10_NC_AND_IS_SUBSCRIBED;
  }
}

export const checkIfUserCanAccessSubscriberServices = (notesCreatedCount, isSubscribed, subscriptionExpirationDate) => {

  const customerType = getCustomerType(notesCreatedCount, isSubscribed);

  if (customerType === CUSTOMER_TYPE_ENUM.LESS_THAN_10_NC_AND_NOT_SUBSCRIBED) {
    return true;
  } else if (customerType === CUSTOMER_TYPE_ENUM.LESS_THAN_10_NC_AND_IS_SUBSCRIBED) {
    return true;
  } else if (customerType === CUSTOMER_TYPE_ENUM.GREATER_THAN_10_NC_AND_NOT_SUBSCRIBED) {
    return false;
  } else if (customerType === CUSTOMER_TYPE_ENUM.GREATER_THAN_10_NC_AND_IS_SUBSCRIBED) {
    const now = new Date();
    const expirationDate = new Date(subscriptionExpirationDate);
    return now < expirationDate;
  }
  //TODO: Handle this error
  // console.error("Error in userCanAccessSubscriberServices: customerType not recognized");
  return false;
}

export const generateDateStringInFormatMMDDYYYYFromDate = (firestoreTimestamp) => {
  // console.log("rawDateInt",firestoreTimestamp)
  const date = new Date(firestoreTimestamp);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const dateString = `${month}/${day}/${year}`;
  // console.log("dateString",dateString)
  return dateString;
}


export const calculateProratedRefundAmount = (firestoreTimestamp) => {
  const currentTime = Date.now(); // Current time in milliseconds
  const endTime = firestoreTimestamp.seconds * 1000; // Subscription end time in milliseconds

  const timeLeft = endTime - currentTime;
  const subscriptionPrice = 75;

  if (timeLeft <= 0) {
    return 0;
  }

  const daysLeft = timeLeft / (1000 * 60 * 60 * 24); // Convert milliseconds to days
  const dailyRate = subscriptionPrice / 30; // Assuming 30 days in a month
  let refundAmount = Math.round(dailyRate * daysLeft);
  refundAmount = Math.min(refundAmount,subscriptionPrice)

  return refundAmount;
};



const initDB = async () => {
  return openDB('SOAP_NOTES_IDB', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('notes')) {
        db.createObjectStore('notes', { keyPath: 'soapNoteId' });
      }
    },
  });
};

export const saveNoteToIndexedDB = async (note, savePoint) => {
  const db = await initDB();
  const tx = db.transaction('notes', 'readwrite');
  const store = tx.objectStore('notes');
  note.savePoint = savePoint;
  await store.put(note);
  await tx.done;
};

export const fetchNotesFromIndexedDB = async () => {
  const db = await openDB('SOAP_NOTES_IDB', 1);
  const tx = db.transaction('notes', 'readonly');
  const store = tx.objectStore('notes');
  const allNotes = await store.getAll();
  await tx.done;

  return allNotes.map(note => ({
    ...note,
    source: 'indexedDb'  // Mark as IndexedDB source
  }));
};

  // const fetchDummyAudioBlob = async (localAudio, mimeType) => {
  //   try{
  //     //In the real thing, I'll fetch the audio blob from the context
  //     const response = await fetch(localAudio)
  //     const arrayBuffer = await response.arrayBuffer();
  //     const blob = new Blob([arrayBuffer], { type: mimeType });
  //     return blob;
  //   } catch{
  //     console.error("Failed to fetch dummy audio blob");
  //   }
  // }

    // const loadDummyAudioBlob = async (localAudio, mimeType) => {
  //   const dummyAudioBlob = await fetchDummyAudioBlob(localAudio, mimeType);
  //   if (!dummyAudioBlob) {
  //     return;
  //   }
  //   const audioBlobUrl = URL.createObjectURL(dummyAudioBlob);
  //   setDummyAudioBlobUrl(audioBlobUrl);

  //   // Cleanup function to revoke the object URL
  //   return () => {
  //     URL.revokeObjectURL(audioBlobUrl);
  //   };
  // };
