import darkPurpleCheckmarkCircle from '../../Images/darkPurpleCheckmarkCircle.png'
import oneCircle from '../../Images/oneCircle.png'
import darkPurpleTwoCircle from '../../Images/darkPurpleTwoCircle.png'
import darkPurpleThreeCircle from '../../Images/darkPurpleThreeCircle.png'
import darkRedLeftArrowCircle from '../../Images/darkRedLeftArrowCircle.png'
import threeCircle from '../../Images/threeCircle.png'


import blueResumeIcon from '../../Images/blueResumeIcon.png'
import darkPurpleRightArrowIcon from '../../Images/darkPurpleRightArrowIcon.png'
// import redCancelIcon from '../../Images/redCancelIcon.png' 
import whitePauseIcon from '../../Images/whitePauseIcon.png'

import IndeterminateLoader from '../../UtilityComponents/IndeterminateLoader';
import React from 'react'
import RECORDING_PAGE_ENUM from '../../Enums/RECORDING_PAGE_ENUM';
import useRecordingSessionPageVM from "./useRecordingSessionPageVM";
import { useEffect, useRef } from 'react'
import BgLoggedIn from '../../ReusableUI/BgLoggedIn'
import { Toaster } from 'sonner'
import Spinner from '../../UtilityComponents/Spinner'




export default function RecordingSessionPage2(){

  const siriWaveContainerRef = useRef(null);
    
  const viewModel = useRecordingSessionPageVM(siriWaveContainerRef);
  
  
  // This makes the transcript scroll to the bottom so that new text is always visible
  const endOftranscriptionRef = useRef(null);
  useEffect(() => {
    endOftranscriptionRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [viewModel.transcript]);
  

  return (
    <div id="bg-logged-in" className="full-height-container bg-cover bg-center h-screen flex flex-col justify-start items-center">

      <BgLoggedIn/>

      <Toaster/>

      <div id="navbar-and-divider" className="w-full absolute top-0">
        <div id="navbar-logged-in" className='flex flex-row justify-center w-full px-8 pt-4 pb-2'>

          <div id="Magic-Medic-AI-title" className='mt-1'>
            <p id="Magic-Medic-AI-title" className='font-bold text-white text-lg'>
              Magic Medic AI
            </p>
          </div>
        </div>

        <div id="navbar-divider" className="border-t border-gray-200 my-0"></div>
      </div>

      <div id='stuff-below-the-nav-bar' className='flex flex-col items-center space-y-24 mt-20'>
        
        <div id='tp-progress-indicator-and-divider' className='w-full absolute'>
            <div id='tp-progress-indicator'  className='flex flex-row justify-center items-center space-x-4' >
              
              <div id='info-col' className='flex flex-col items-center space-y-2 mr-4'>
                <img src={darkPurpleCheckmarkCircle} alt="checkmark-circle" className='size-7'/>
                <p className='text-white text-sm text-opacity-50 font-semibold'>
                  Info
                </p>
              </div>

              <div id='empty-rounded-rectangle' className='w-16 h-2 bg-[#5E209B] rounded-md -mt-8'/>

              <div id='recording-col' className='flex flex-col items-center space-y-2'>
                <img src={darkPurpleTwoCircle} alt="twoCircle"  className='size-6'/>
                <p className='text-white font-semibold text-sm'>
                  Recording
                </p>
              </div>

              <div id='empty-rounded-rectangle' className='w-16 h-2 bg-white rounded-md -mt-8'/>

              <div id='review-col' className='flex flex-col items-center space-y-2'>
                <img src={threeCircle} alt="threeCircle"  className='size-6'/>
                <p className='text-white text-opacity-50 font-semibold text-sm'>
                  Review
                </p>
              </div>

            </div>
            
            <div id="navbar-divider" className="border-t border-gray-200 mt-4"></div>
        </div>

        {/* Recording Operations */}
        {(viewModel.currentRecordingPageState === RECORDING_PAGE_ENUM.STARTED ||
          viewModel.currentRecordingPageState === RECORDING_PAGE_ENUM.PAUSED ||
          viewModel.currentRecordingPageState === RECORDING_PAGE_ENUM.RESUMED ||
          viewModel.currentRecordingPageState === RECORDING_PAGE_ENUM.CLOSE_ATTEMPT ||
          viewModel.currentRecordingPageState === RECORDING_PAGE_ENUM.DONE_ATTEMPT
        )&& (
        <div id="non-navbar-elements-of-page" className='absolute mt-54 flex flex-col items-center'>
          <div id="transcription-buttons" className='flex flex-row items-center space-x-24 px-4'>
            
              <button id="cancel-button" className='flex flex-row items-center space-x-2 hover:opacity-50'
                onClick={viewModel.handleCloseButtonClick}
              >
                <img src={darkRedLeftArrowCircle} alt="cancel-button" className='size-8'/>
                <p className='text-white font-medium opacity-50'>
                  Back
                </p>
              </button>

              <button id="pause-button" className='flex flex-row space-x-2 hover:opacity-50'
                onClick={viewModel.handlePauseResumeButtonClick}
              >
                <img src={viewModel.currentRecordingPageState === RECORDING_PAGE_ENUM.PAUSED ? blueResumeIcon : whitePauseIcon} alt="pause-button" className='w-6'/>
                <p className='text-white font-medium opacity-50'>
                  {viewModel.currentRecordingPageState === RECORDING_PAGE_ENUM.PAUSED ? "Play" : "Pause"}
                </p>
              </button>
            
              <button id="done-button" className='flex flex-row items-center space-x-2 hover:opacity-50'
                onClick={viewModel.handleDoneButtonClick}
              >
                <img src={darkPurpleRightArrowIcon} alt="done-button" className='size-8'/>
                <p className='text-white font-medium opacity-50'>
                  Done
                </p>
              </button>

          </div>

          <div id='siri-wave-container'
            ref={siriWaveContainerRef} 
            className="siri-container flex justify-center px-8 lg:w-[550px] w-[420px] h-[160px]" 
          />
          
          <div id='transcript-container'
            className='max-h-[400px] overflow-auto'>
            <p className='text-white font-semibold leading-10 w-[420px] pr-4'>
              {viewModel.transcript|| "Ready to go .."} 
            </p>
            <div ref={endOftranscriptionRef} />
          </div>
          
        </div>
        )}  

        {viewModel.currentRecordingPageState === RECORDING_PAGE_ENUM.UPLOAD_IN_PROGRESS && (
        <div id="non-navbar-elements-of-page" className='absolute mt-60 flex flex-col space-y-4'>
          <p className='font-bold text-white text-md text-center'>
            Please do not refresh, processing typically takes 1-3 minutes
          </p>
          <div className=''>
            <IndeterminateLoader/>
          </div>
          {/* { viewModel.savableNote && (
          <>
          <p className='font-bold text-white text-md text-center'>
              Or
          </p>
          <p className='font-bold text-white text-md text-center'>
              click the save button to come back when processing is complete.
          </p>
          <button id="start-new-session-button" className="relative px-8 py-3 bg-gray-900 w-50 rounded-3xl hover:opacity-50 flex-none"
            onClick={viewModel.handleSaveButtonClick}
          >
            <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
            <p className="text-[#A343FF] font-bold relative">Save</p>
          </button>
          </>
          )} */}
        </div>
        )}

        {viewModel.currentRecordingPageState === RECORDING_PAGE_ENUM.SAVING_IN_PROGRESS && (
        <div id="non-navbar-elements-of-page" className='absolute mt-60 flex flex-col space-y-4'>
          <p className='font-bold text-white text-md text-center'>
            Saving, please wait..
          </p>
          <div className=''>
            <Spinner/>
          </div>
        </div>
        )}

        {viewModel.currentRecordingPageState === RECORDING_PAGE_ENUM.SAVED_SUCCESSFULLY && (
        <div id="non-navbar-elements-of-page" className='absolute mt-60 flex flex-col space-y-4'>
          <p className='font-bold text-white text-md text-center'>
            Your work has been saved successfully.
          </p>
          <button id="start-new-session-button" className="relative px-8 py-3 bg-gray-900 w-50 rounded-3xl hover:opacity-50 flex-none"
            onClick={viewModel.handleStartNewSessionButtonClick}
          >
            <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
            <p className="text-[#A343FF] font-bold relative">Start new session</p>
          </button>
        </div>
        )}

        {viewModel.currentRecordingPageState === RECORDING_PAGE_ENUM.ERROR_STATE && (
          <div className='flex flex-col items-center space-y-4'>
            <p className='font-bold text-white text-md text-center'>
              An error occurred. Don't worry, your work has been saved.
            </p>
            <p className='font-bold text-white text-md text-center'>
              Visit the notes page in 5 minutes to resume where you left off.
            </p>
            <button id="start-new-session-button" className="relative px-8 py-3 bg-gray-900 w-50 rounded-3xl hover:opacity-50 flex-none"
              onClick={viewModel.handleStartNewSessionButtonClick}
            >
                <div className="absolute inset-0 border-2 border-[#A343FF] rounded-3xl pointer-events-none"></div>
                <p className="text-[#A343FF] font-bold relative">Start new session</p>
            </button>

          </div>
        )}
        
      </div>

    </div>
  );

};