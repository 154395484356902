import AWS from 'aws-sdk';
import awsConfig from '../../Configs/awsConfig.js'; 
import { createClient, LiveTranscriptionEvents } from "@deepgram/sdk";
import { GlobalContext } from '../../UtilityComponents/GlobalContextProvider.js'
import RECORDING_PAGE_ENUM from "../../Enums/RECORDING_PAGE_ENUM.js";
import SiriWave from 'siriwave';
import TRANSCRIPTION_PAGE_ENUM from "../../Enums/TRANSCRIPTION_PAGE_ENUM.js";
import { TranscriptionProcessContext } from "../Transcription_Process_MultiPage/TranscriptionProcessContext.jsx";
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import S3 from 'aws-sdk/clients/s3.js'; 
import SOAP_NOTE_STATUS_ENUM from '../../Enums/SOAP_NOTE_STATUS_ENUM.js';
import {
  getFileExtensionForMimeType,
  getIso8601Timestamp,
  generateObjectKey,
  saveNoteToIndexedDB
} from '../../UtilityFunctions/utils.js';
import { toast } from 'sonner';
// import TRANSCRIPTION_PROCESS_SAVE_POINT_ENUM from '../../Enums/TRANSCRIPTION_PROCESS_SAVE_POINT_ENUM.js';

// #region AWS setup
// I'm initializing these AWS services outside of the hook
// to avoid re-creating it on every render.
AWS.config.update({
  region: awsConfig.s3.region,
  accessKeyId: awsConfig.s3.accessKeyId,
  secretAccessKey: awsConfig.s3.secretAccessKey,
});

const s3 = new S3({
  region: awsConfig.s3.region,
  accessKeyId: awsConfig.s3.accessKeyId,
  secretAccessKey: awsConfig.s3.secretAccessKey
});

var ddb = new AWS.DynamoDB({ apiVersion: "2012-08-10" });
// #endregion AWS setup


const useRecordingSessionPageVM = ( siriWaveContainerRef )=>{

  // #region Context(s)
  const {
    setCurrentTranscriptionPage,
    patientName, patientAge, patientSex,
    setAudioBlobRef,
    setAudioBlobUrl,
    soapNoteId,
  } = useContext(TranscriptionProcessContext)

  const { user } = useContext(GlobalContext)
  // #endregion
  

  // #region Properties
  const [audioChunks, setAudioChunks] = useState([]);
  const animationFrameIdRef = useRef();
  const analyserRef = useRef(null);
  const audioContextRef = useRef(null);
  const [currentRecordingPageState, setCurrentRecordingPageState] = useState(RECORDING_PAGE_ENUM.STARTED)
  const dataArrayRef = useRef(null);
  const deepgramClient = createClient(process.env.REACT_APP_DEEPGRAM_API_KEY);
  const deepgramConnectionRef = useRef(null);
  const [errorIsPresented, setErrorIsPresented] = useState(false);
  const [lastSavePoint, setLastSavePoint] = useState(SOAP_NOTE_STATUS_ENUM.BEFORE_UPLOADING_METADATA)
  const mediaRecorderRef = useRef(null);
  const [mimeTypeSupportedByTheUsersBrowser, setMimeTypeSupportedByTheUsersBrowser] = useState("");
  const [savableNote, setSavableNote] = useState(null);
  const shouldContinueWaveRef = useRef(true);
  const siriWaveRef = useRef(null);
  const sourceRef = useRef(null);
  const streamRef = useRef(null);
  const [transcript, setTranscript] = useState('') 
  const [wakeLock, setWakeLock] = useState(null);

  // const [currentRecordingPageUIState, setCurrentRecordingPageUIState] = useState(RECORDING_PAGE_UI_ENUM.ERROR_STATE)

  const SPEED_CONST = 0.35;
  const FREQUENCY_CONST = 8.5;
  // #endregion


  // #region Methods
  const addAudioChunk = (chunk) => {
    setAudioChunks(prevChunks => [...prevChunks, chunk]);
  };

  const closeAttemptAction = ()=>{
    pauseRecording();
    releaseWakeLock(); // Release wake lock when closing

    const userConfirmedCloseAction = (window.confirm("Are you sure you want to close. Your work won't be saved."));
    if (!userConfirmedCloseAction){
      setCurrentRecordingPageState(RECORDING_PAGE_ENUM.RESUMED);
      return;
    }
    setCurrentTranscriptionPage(TRANSCRIPTION_PAGE_ENUM.SESSION_INFO)    
  };

  const configureAudioCapture = () => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    sourceRef.current = audioContextRef.current.createMediaStreamSource(streamRef.current);
    analyserRef.current = audioContextRef.current.createAnalyser();
    sourceRef.current.connect(analyserRef.current);
    analyserRef.current.fftSize = 256;
    const bufferLength = analyserRef.current.frequencyBinCount;
    dataArrayRef.current = new Uint8Array(bufferLength);
  };

  const configureMediaRecorder = () => {
    const possibleSupportedMimeTypes = [
      'audio/webm',
      'audio/mp4',
      'audio/webm;codecs=opus',
      'audio/ogg',
    ];
  
    let supportedMimeType;
    // Check for supported MIME type and set it using the setMimeType function
    for (const mimeType of possibleSupportedMimeTypes) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        supportedMimeType = mimeType;
        setMimeTypeSupportedByTheUsersBrowser(supportedMimeType); // Set the MIME type using useState updater function
        // console.log(`Using supported MIME type: ${supportedMimeType}`);
        break; // Stop checking once we find the first supported type
      }
    }
  
    if (!supportedMimeType) {
      //TODO: Handle this error
      // console.error('No supported MIME types found.');
      return; // Exit the function if no supported MIME type is set
    }
  
    // Assuming streamRef.current is already assigned to the relevant media stream
    mediaRecorderRef.current = new MediaRecorder(streamRef.current, { supportedMimeType });
    mediaRecorderRef.current.addEventListener('dataavailable', async (event) => {
      if (event.data.size === 0){
        console.warn("No data available in the event");
        return;
      }

      if (!deepgramConnectionRef.current) {
        console.warn("Deepgram connection is not available.");
        return;
      }
    
      if (deepgramConnectionRef.current.getReadyState() !== 1) {
        console.warn("Connection to Deepgram is not open.");
        return;  // Early exit if the connection is not open
      }
    
      // If all checks pass, then proceed with sending the data and adding the audio chunk
      deepgramConnectionRef.current.send(event.data);
      addAudioChunk(event.data);  // Ensure addAudioChunk 
    });
    mediaRecorderRef.current.start(1000); // Start recording with a timeslice of 1000ms
  };

  const doneAttemptAction = async () => {
    // #region 1. request confirmation from the user, resume if user cancels
    pauseRecording();
    const userConfirmedDoneAction = window.confirm("Click ok to confirm that you are done");
    if (!userConfirmedDoneAction) {
      setCurrentRecordingPageState(RECORDING_PAGE_ENUM.RESUMED);
      return;
    }
    // #endregion
  
    // #region 2. Set up the variables needed for uploading data to the backend
    setCurrentRecordingPageState(RECORDING_PAGE_ENUM.UPLOAD_IN_PROGRESS);
    const fileExtension = getFileExtensionForMimeType(mimeTypeSupportedByTheUsersBrowser);
    const objectKey = generateObjectKey(user.uid, soapNoteId, fileExtension);
    const audioBlob = new Blob(audioChunks, { type: mimeTypeSupportedByTheUsersBrowser });
    setAudioBlobRef(audioBlob);
    const audioBlobUrl = URL.createObjectURL(audioBlob);
    setAudioBlobUrl(audioBlobUrl);
  
    const patientAgeString = patientAge.toString();
    const trimmedPatientName = patientName.trim();
    const timestamp = getIso8601Timestamp();
    const note = {
      soapNoteId,
      objectKey,
      patientName: trimmedPatientName,
      patientAge: patientAgeString,
      patientSex,
      timestamp,
      audioBlob,
    };
    setSavableNote(note);
    // #endregion

    // #region 3. Save a copy of the note to IndexedDB
    // await saveNoteToIndexedDB(note, SOAP_NOTE_STATUS_ENUM.BEFORE_UPLOADING_METADATA);
    // #endregion

    // #region 3. upload metadata to DDB
    try{
      const ddbParams = {
        TableName: "SOAP_NOTES_TABLE",// CHANGE THIS
        Item: {
          "SOAP_NOTE_ID": { S: soapNoteId },
  
          "OBJECT_KEY": { S: objectKey },
          "PATIENT_NAME": { S: trimmedPatientName },
          "PATIENT_AGE": { S: patientAgeString },
          "PATIENT_SEX": { S: patientSex },
          "SOAP_NOTE_STATUS": { S: SOAP_NOTE_STATUS_ENUM.TRANSCRIBING }, 
          "DDB_TIMESTAMP": { S: timestamp },
          "USER_ID": { S: user.uid },
        }
      }
      await ddb.putItem(ddbParams).promise();
  

      //the uploading to ddb is complete, therefore, the current save point is UPLOADING_TO_S3
      // await saveNoteToIndexedDB(note, SOAP_NOTE_STATUS_ENUM.UPLOADING_TO_S3);
      // setLastSavePoint(SOAP_NOTE_STATUS_ENUM.UPLOADING_TO_S3);

    } catch {
      // await saveNoteToIndexedDB(note, SOAP_NOTE_STATUS_ENUM.UPLOADING_TO_DDB);
      setErrorIsPresented(true);
      setCurrentRecordingPageState(RECORDING_PAGE_ENUM.ERROR_STATE);
      return;
    }
    // #endregion

    // #region 4. Upload the audio file to S3
    try {
      const s3Params = { 
        Bucket: process.env.REACT_APP_AFRT_BUCKET_NAME,//CHANGE THIS
        Key: objectKey,
        Body: audioBlob,
        ContentType: mimeTypeSupportedByTheUsersBrowser
      };
      await s3.upload(s3Params).promise();


      //the uploading to s3 is complete, therefore, the current save point is TRANSCRIBING
      // await saveNoteToIndexedDB(note, SOAP_NOTE_STATUS_ENUM.TRANSCRIBING);
      setLastSavePoint(SOAP_NOTE_STATUS_ENUM.TRANSCRIBING);

    } catch {

      // await saveNoteToIndexedDB(note, SOAP_NOTE_STATUS_ENUM.UPLOADING_TO_S3);
      setErrorIsPresented(true);
      setCurrentRecordingPageState(RECORDING_PAGE_ENUM.ERROR_STATE);
      return;

    }
    // #endregion
  
    // #region 5. Poll the backend to check if the raw transcription is ready
    try {
      const pollParams = {
        TableName: "SOAP_NOTES_TABLE",
        Key: { "SOAP_NOTE_ID": { "S": soapNoteId } },
        ProjectionExpression: "SOAP_NOTE_STATUS"
      };
  
      await pollToCheckIfRawTranscriptionIsReady(pollParams);
    } catch {
      // await saveNoteToIndexedDB(note, SOAP_NOTE_STATUS_ENUM.TRANSCRIBING);
      setErrorIsPresented(true);
      setCurrentRecordingPageState(RECORDING_PAGE_ENUM.ERROR_STATE);
      return;
    }
    // #endregion
    
    // #region 6. Handle the raw transcript being ready
    setAudioChunks([]);
    // await saveNoteToIndexedDB(note, SOAP_NOTE_STATUS_ENUM.RAW_TRANSCRIPT_COMPLETED);
    setCurrentTranscriptionPage(TRANSCRIPTION_PAGE_ENUM.CORRECTIONS);
    // #endregion
  }

  const initializeAudioContextAndAnalyser = (stream) => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    if (!analyserRef.current) {
      // console.log("Creating a new analyser")
      analyserRef.current = audioContextRef.current.createAnalyser();
    }
    const source = audioContextRef.current.createMediaStreamSource(stream);
    source.connect(analyserRef.current);
    analyserRef.current.fftSize = 256;
    dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);
  };

  const initializeDeepgramConnection = () => {
    try{
      deepgramConnectionRef.current = deepgramClient.listen.live({
        channels: 2,
        language: "en-US",
        model: "nova-2-medical",
        punctuate: true,
        sample_rate: 44100,
      });
  
      deepgramConnectionRef.current.on(LiveTranscriptionEvents.Open, () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'inactive') {
          configureMediaRecorder(streamRef.current, mimeTypeSupportedByTheUsersBrowser, addAudioChunk);
        }
      });
  
      deepgramConnectionRef.current.on(LiveTranscriptionEvents.Transcript, (data) => {
        if (data.is_final) {
          const transcriptText = data.channel.alternatives[0].transcript;
          setTranscript(prevTranscript => `${prevTranscript}\n${transcriptText}`);
        }
      });
  
      deepgramConnectionRef.current.on(LiveTranscriptionEvents.Close, () => {
          
      });
      deepgramConnectionRef.current.on(LiveTranscriptionEvents.Error, (error) => {
        toast.error("Please check your internet connection");
        //TODO: Handle this error
        // console.error("Deepgram connection error:", error)
      });
    }catch (error){
      toast.error("Please check your internet connection");
      //TODO: Handle this error
      // console.error("Error caught while trying to initialize the Deepgram connection", error)
    }
  };

  const initializeSiriWave = () => {
    try {
      siriWaveRef.current = new SiriWave({
        container: siriWaveContainerRef.current,
        autostart: true,
        style: 'ios',
        frequency: FREQUENCY_CONST,
        speed: SPEED_CONST,
      });
    } catch (error) {
      //I don't know what could cause this error and as such, I'm not sure how to handle it
      // other than to write test to ensure that it doesn't happen
    }
  };

  const optimizeAudioCapture = () => {
    const gainNode = audioContextRef.current.createGain();
    gainNode.gain.value = 1.5; // Adjust this value to amplify the audio signal
    sourceRef.current.connect(gainNode);
    gainNode.connect(analyserRef.current);

    const compressor = audioContextRef.current.createDynamicsCompressor();
    compressor.threshold.setValueAtTime(-50, audioContextRef.current.currentTime); // Decibels
    compressor.knee.setValueAtTime(40, audioContextRef.current.currentTime); // Decibels
    compressor.ratio.setValueAtTime(12, audioContextRef.current.currentTime);
    compressor.attack.setValueAtTime(0, audioContextRef.current.currentTime); // Seconds
    compressor.release.setValueAtTime(0.25, audioContextRef.current.currentTime); // Seconds
    sourceRef.current.connect(compressor);
    compressor.connect(analyserRef.current);

  };

  const pauseRecording = ()=>{
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
  
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
      // console.log("Attempting to stop the mediaRecording")
      mediaRecorderRef.current.stop();
      // console.log("Recording stopped");
      if (mediaRecorderRef.current){
        // console.log("mediaRecorderRef.current.state is", mediaRecorderRef.current.state)
      } else {
        // console.log("There is no ref to the mediaRecorder")
      }
    }
  
    if (deepgramConnectionRef.current && deepgramConnectionRef.current.state === 'OPEN') {
      deepgramConnectionRef.current.finish(); // Use finish() to close the Deepgram connection
    }
  
    siriWaveRef.current?.setAmplitude(0);
    shouldContinueWaveRef.current = false;
  
    // Only do this if you're completely done with the AudioContext and won't need to use it again
    if (audioContextRef.current && audioContextRef.current.state !== 'closed'){
      // console.log("AudioContextRef state is", audioContextRef.current.state)
      audioContextRef.current?.close().then(() => {
         // console.log("AudioContext closed"));
      }) 
    }
    releaseWakeLock(); // Release wake lock when pausing

  };

  const pollToCheckIfRawTranscriptionIsReady = (pollParams) => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        try {
          const { Item } = await ddb.getItem(pollParams).promise();
          if (Item && Item.SOAP_NOTE_STATUS.S === SOAP_NOTE_STATUS_ENUM.RAW_TRANSCRIPT_COMPLETED) {
            clearInterval(interval);
            resolve();
          }
        } catch (error) {
          clearInterval(interval);
          reject(error);
        }
      }, 5000);
    });
  }

  const requestWakeLock = async () => {
    try {
      const wakeLock = await navigator.wakeLock.request('screen');
      setWakeLock(wakeLock);
      wakeLock.addEventListener('release', () => {
        console.log('Wake Lock was released');
        setWakeLock(null);
      });
      console.log('Wake Lock is active');
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  const releaseWakeLock = () => {
    if (wakeLock) {
      wakeLock.release()
        .then(() => setWakeLock(null))
        .catch((err) => console.error('Error releasing wake lock:', err));
    }
  };

  const resetReferences = ()=>{ 
    try {
        if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
          audioContextRef.current.close();
        }
        mediaRecorderRef.current?.stop();
        deepgramConnectionRef.current?.finish();
    } catch (error){
      //I don't know what could cause this error and as such, I'm not sure how to handle it
      // other than to write test to ensure that it doesn't happen
    }
  }
  
  const resumeRecording = useCallback(()=>{
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      streamRef.current = stream;
  
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      analyserRef.current = audioContextRef.current.createAnalyser();
      source.connect(analyserRef.current);
      analyserRef.current.fftSize = 256;
      dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);
      
  
      if (siriWaveRef.current) {
        siriWaveRef.current.start();
      } else {
        // Reinitialize SiriWave if it was disposed
        siriWaveRef.current = new SiriWave({
          container: siriWaveContainerRef.current,
          autostart: true,
          style: 'ios',
          frequency: FREQUENCY_CONST,
          speed: SPEED_CONST,
        });
      }
  
      // Restart the visual animation
      shouldContinueWaveRef.current = true;
      requestAnimationFrame(updateWave);
  
      // Reinitialize MediaRecorder
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: mimeTypeSupportedByTheUsersBrowser });
      // console.log("Default MIME Type:", mediaRecorderRef.mimeType);

      // Attach event listeners to mediaRecorderRef.current as needed
      try{
        mediaRecorderRef.current.start(1000); // Example: Start recording with 1-second chunks
      } catch {
        toast.error("Unable to start recording. Please make sure you enable microphone access.");
        return;
      }
  
      // Reestablish connection to Deepgram
      deepgramConnectionRef.current = deepgramClient.listen.live({
        model: "nova-2-medical",
        language: "en-US",
        punctuate: true,
      });

      // Handle Connection Open Event
      deepgramConnectionRef.current.on(LiveTranscriptionEvents.Open, () => {

        // console.log("Inside the deepgramConnection.Open for the resumeAction.\nmediaRecorderRef.current.state is", mediaRecorderRef.current.state)
        // Ensure mediaRecorderRef.current is initialized and not already recording
        if (mediaRecorderRef.current && (mediaRecorderRef.current.state === 'inactive' || mediaRecorderRef.current.state === 'recording') ) {
          mediaRecorderRef.current.addEventListener('dataavailable', async (event) => {
            try {
              if (event.data.size > 0 && deepgramConnectionRef.current) {
                if (deepgramConnectionRef.current.getReadyState() === 1) {
                  deepgramConnectionRef.current.send(event.data);
                  // audioChunksRef.current.push(event.data);
                  addAudioChunk(event.data);
                } else {
                  console.warn("Connection to Deepgram is not open.");
                }
              }
            } catch (error) {
              // console.error('Error sending data to Deepgram:', error);
              //TODO: Handle this error
            }
          });

        }
        //Error handling 
        else {
            
          if (!mediaRecorderRef.current){
            // console.log("There is no current mediaRecorderRef");
          } else if (mediaRecorderRef.current !== 'inactive'){
            // console.log("There is already another mediaRecorder initialized");
          } else{
            // console.log("Unkown Error");
          }
        }
      });

      // Handle transcription events
      deepgramConnectionRef.current.on(LiveTranscriptionEvents.Transcript, (data) => {
        try {
          if (data.is_final) {
            const transcriptText = data.channel.alternatives[0].transcript;

            setTranscript(prevTranscript => `${prevTranscript}\n${transcriptText}`);
          }
        } catch (error) {
          toast.error("Please check your internet connection"); 
        }
      });

      // Handle the error and closed states
      deepgramConnectionRef.current.on(LiveTranscriptionEvents.Close, () =>{
      }) 
      deepgramConnectionRef.current.on(LiveTranscriptionEvents.Error, (error) => {
        toast.error("Please check your internet connection");
      });

    }).catch(err => {
      toast.error("Unable to resume recording. Please make sure you enable microphone access.");
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTranscript]);

  const setStreamReference = (stream) => {
    if (!streamRef.current) {
      streamRef.current = stream;
    }
  };
  
  const setupRefreshWarning = () => {
    const warnOnRefreshAttempt = (event) => {
      event.preventDefault();
      event.returnValue = ''; // This is required for most browsers to display the warning dialog
    };
  
    window.addEventListener('beforeunload', warnOnRefreshAttempt);
  
    return () => {
      window.removeEventListener('beforeunload', warnOnRefreshAttempt);
    };
  };
  
  const startRecording = useCallback(()=>{
    
    initializeSiriWave();
  
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {

        setStreamReference(stream);
        configureAudioCapture();
        optimizeAudioCapture();
        updateWave();
        configureMediaRecorder();        
        initializeDeepgramConnection();
        requestWakeLock(); // Request wake lock when starting recording


      })
      .catch(err => {
        toast.error("Please enable microphone access to start recording.");
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTranscript]);

  const updateWave = ()=>{
    if (!shouldContinueWaveRef.current) {
      siriWaveRef.current?.setAmplitude(0);
      return;
    }

    analyserRef.current.getByteFrequencyData(dataArrayRef.current);
    let sum = dataArrayRef.current.reduce((acc, val) => acc + val, 0);
    let average = sum / dataArrayRef.current.length;
    let amplitude = Math.max(average / 100, 0.15);
    siriWaveRef.current.setAmplitude(amplitude);

    // Schedule the next frame and store the ID
    animationFrameIdRef.current = requestAnimationFrame(updateWave);
  };
  // #endregion
  

  // #region Button handlers
  const handleCloseButtonClick = ()=>{
    setCurrentRecordingPageState(RECORDING_PAGE_ENUM.CANCEL_ATTEMPT)
  };

  const handleDoneButtonClick = ()=>{
    setCurrentRecordingPageState(RECORDING_PAGE_ENUM.DONE_ATTEMPT)
  };

  const handlePauseResumeButtonClick = ()=>{
    if (currentRecordingPageState === RECORDING_PAGE_ENUM.STARTED){
      setCurrentRecordingPageState(RECORDING_PAGE_ENUM.PAUSED)
    } else if (currentRecordingPageState === RECORDING_PAGE_ENUM.PAUSED){
      setCurrentRecordingPageState(RECORDING_PAGE_ENUM.RESUMED)
    } else if (currentRecordingPageState === RECORDING_PAGE_ENUM.RESUMED){
      setCurrentRecordingPageState(RECORDING_PAGE_ENUM.PAUSED)
    }
  };

  const handleSaveButtonClick = ()=>{
    setCurrentRecordingPageState(RECORDING_PAGE_ENUM.SAVING_IN_PROGRESS)
    // saveNoteToIndexedDB(savableNote, lastSavePoint)
    setCurrentRecordingPageState(RECORDING_PAGE_ENUM.SAVED_SUCCESSFULLY)
  }

  const handleStartNewSessionButtonClick = ()=>{
    setCurrentTranscriptionPage(TRANSCRIPTION_PAGE_ENUM.SESSION_INFO)
  }
  // #endregion Button Handlers
  

  // #region UseEffect (In this case, it's the main driver of the page)
  useEffect(() => {
    if (currentRecordingPageState === RECORDING_PAGE_ENUM.STARTED){
      startRecording();
    } else if (currentRecordingPageState === RECORDING_PAGE_ENUM.PAUSED){
      pauseRecording();
    } else if (currentRecordingPageState === RECORDING_PAGE_ENUM.RESUMED){
      resumeRecording();
    } else if (currentRecordingPageState === RECORDING_PAGE_ENUM.CANCEL_ATTEMPT){
      closeAttemptAction();
    } else if (currentRecordingPageState === RECORDING_PAGE_ENUM.DONE_ATTEMPT){
      doneAttemptAction();
    } else if (currentRecordingPageState === RECORDING_PAGE_ENUM.ERROR_STATE){
      return;
    } else if (currentRecordingPageState === RECORDING_PAGE_ENUM.UPLOAD_IN_PROGRESS){
      return;
    }

    const removeRefreshWarning = setupRefreshWarning();
    
    return ()=>{ 
      resetReferences() 
      requestWakeLock(); // Request wake lock when starting recording
      removeRefreshWarning();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRecordingPageState, savableNote]);
  // #endregion


  // #region Return statement
  return {
    currentRecordingPageState,

    errorIsPresented,

    handleCloseButtonClick,

    handleDoneButtonClick,

    handlePauseResumeButtonClick,

    handleStartNewSessionButtonClick,

    handleSaveButtonClick,

    savableNote,

    transcript,

    initializeSiriWave,

    initializeAudioContextAndAnalyser,

    setMimeTypeSupportedByTheUsersBrowser, mimeTypeSupportedByTheUsersBrowser,    
  };
  // #endregion

};

export default useRecordingSessionPageVM;

  