import AWS from 'aws-sdk';
import awsConfig from '../Configs/awsConfig.js'; 
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, { createContext, useState, useEffect } from 'react';
import useAuth from './useAuth';


export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {

  const { user, setUser, loading } = useAuth();

  return (
    <GlobalContext.Provider value={{
      user, setUser, loading
    }}>
      {children}
    </GlobalContext.Provider>
  );
};
